import { useCallback, useEffect, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';
import { Toast, BankAccountToast } from './types';
import { AccountSectionItem } from 'types/assets/section';
import {
  ADD_TOAST_EVENT,
  ADD_NEW_BANK_ACCOUNT_TOAST_EVENT,
  CLEAR_TOAST_EVENT,
  CLEAR_ALL_TOASTS_EVENTS,
  CLEAR_LATEST_TOAST,
  HIDE_CONTEXT_EVENT,
  SHOW_CONTEXT_EVENT,
  CLEAR_GROUP_TOASTS_EVENTS,
} from './constants';
import ToastsContainer from './ToastsContainer';
import { differenceWith, isEqual } from 'lodash';

export const ToastHandler = ({
  id: contextId = '',
  zIndex = 9999,
}: {
  id?: string;
  zIndex?: number;
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [newBankAccountToasts, setNewBankAccountToasts] = useState<
    BankAccountToast[]
  >([]);
  const [newBatchedToasts, setNewBatchedToasts] = useState([] as Toast[]);
  const confirmTimers = useRef<any[]>([]);
  const timeoutRef = useRef<any>();
  const prefToasts = useRef([] as Toast[]);

  const showContext = useCallback(() => {
    setIsVisible(true);
  }, []);

  const hideContext = useCallback(() => {
    setIsVisible(false);
  }, []);

  const addToast = useCallback((event: CustomEvent<Toast>) => {
    const data = event.detail;
    const id = data.id || uuid();
    const toast: Toast = { ...data, id };

    // We need to trigger the change asynchronously to prevent batching, if more than one toast is added at a time then the hidden toasts have wrong height
    // This way only one toast gets added at once

    setToasts((toasts) => [toast].concat(toasts));
  }, []);

  const clearLatestToast = useCallback(() => {
    setToasts((toasts) => toasts.slice(1));
  }, []);

  const clearToast = useCallback(
    (event: CustomEvent<{ toast: Pick<Toast, 'id'> }>) => {
      setToasts((toasts) =>
        toasts.filter((toast) => toast.id !== event.detail.toast.id),
      );
    },
    [],
  );

  const clearAll = useCallback(() => {
    setToasts((prev) => (prev.length === 0 ? prev : []));
  }, []);

  const clearGroup = useCallback((event) => {
    const {
      detail: { group },
    } = event;
    setToasts((toasts) => toasts.filter((toast) => toast.group !== group));
  }, []);

  const addNewBankAccount = useCallback(
    (
      event: CustomEvent<{
        accounts: AccountSectionItem[];
        providerName: string;
      }>,
    ) => {
      const { accounts, providerName } = event.detail;

      const id = uuid();

      setNewBankAccountToasts((toasts) =>
        toasts.concat({
          accounts,
          providerName,
          id,
        }),
      );
    },
    [],
  );

  useEffect(() => {
    const difference = differenceWith(toasts, prefToasts.current, isEqual);
    if (difference.length > 1) {
      setNewBatchedToasts(difference);
    } else {
      setNewBatchedToasts([]);
    }
    prefToasts.current = toasts;
  }, [toasts]);

  // Listen to custom toast events. These can be used to trigger toasts without using a hook, e.g., in sagas
  useEffect(() => {
    window.addEventListener(`${SHOW_CONTEXT_EVENT}${contextId}`, showContext);
    window.addEventListener(`${HIDE_CONTEXT_EVENT}${contextId}`, hideContext);
    window.addEventListener(`${ADD_TOAST_EVENT}${contextId}`, addToast);
    window.addEventListener(`${CLEAR_TOAST_EVENT}${contextId}`, clearToast);
    window.addEventListener(`${CLEAR_ALL_TOASTS_EVENTS}${contextId}`, clearAll);
    window.addEventListener(
      `${CLEAR_GROUP_TOASTS_EVENTS}${contextId}`,
      clearGroup,
    );
    window.addEventListener(
      `${CLEAR_LATEST_TOAST}${contextId}`,
      clearLatestToast,
    );
    window.addEventListener(
      `${ADD_NEW_BANK_ACCOUNT_TOAST_EVENT}${contextId}`,
      addNewBankAccount,
    );
    return () => {
      window.removeEventListener(
        `${SHOW_CONTEXT_EVENT}${contextId}`,
        showContext,
      );
      window.removeEventListener(
        `${HIDE_CONTEXT_EVENT}${contextId}`,
        hideContext,
      );
      window.removeEventListener(`${ADD_TOAST_EVENT}${contextId}`, addToast);
      window.removeEventListener(
        `${CLEAR_TOAST_EVENT}${contextId}`,
        clearToast,
      );
      window.removeEventListener(
        `${CLEAR_ALL_TOASTS_EVENTS}${contextId}`,
        clearAll,
      );
      window.removeEventListener(
        `${CLEAR_GROUP_TOASTS_EVENTS}${contextId}`,
        clearGroup,
      );
      window.removeEventListener(
        `${CLEAR_LATEST_TOAST}${contextId}`,
        clearLatestToast,
      );
      window.removeEventListener(
        `${ADD_NEW_BANK_ACCOUNT_TOAST_EVENT}${contextId}`,
        addNewBankAccount,
      );
    };
  }, [
    showContext,
    hideContext,
    addToast,
    clearToast,
    clearAll,
    clearGroup,
    clearLatestToast,
    addNewBankAccount,
    contextId,
  ]);

  // Clear timeouts on unmount
  useEffect(
    () => () => {
      confirmTimers.current?.forEach(clearTimeout);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  if (!isVisible) return null;

  return (
    <ToastsContainer
      newBatchedToasts={newBatchedToasts}
      newBankAccountToasts={newBankAccountToasts}
      setNewBankAccountToasts={setNewBankAccountToasts}
      toasts={toasts}
      zIndex={zIndex}
    />
  );
};
