import { AssetCategorySlug } from './constants';

export const ASSET_CATEGORY_SLUG_TO_COLOR: Record<AssetCategorySlug, string> = {
  'public-assets': '#805E42',
  equities: '#805E42',
  'us-equities': '#29408F',
  'us-equities-large-cap': '#3E539A',
  'us-equities-smallmid-cap': '#5466A5',
  'us-equities-other': '#6979B1',
  'international-equities': '#0A6A6A',
  'international-equities-developed-ex-us': '#237979',
  'international-equities-emerging-markets': '#3B8888',
  'international-equities-other': '#549797',
  'fixed-income': '#BE7200',
  'us-fixed-income': '#C5801A',
  'international-fixed-income': '#CB8E33',
  tips: '#D29C4D',
  'other-fixed-income': '#D8AA66',
  alternatives: '#C88AD2',
  'real-estate-funds': '#CE96D7',
  'other-alternatives': '#D3A1DB',
  commodities: '#997E68',
  'other-public-assets': '#A68E7B',
  'company-equity': '#503F9A',
  'real-estate': '#833090',
  'private-investments': '#3c2f74',
  'fund-investments': '#644FC1',
  'private-equity-fund': '#7461C7',
  'venture-capital-fund': '#8372CD',
  'real-estate-private-fund': '#9384D4',
  'private-credit-fund': '#A295DA',
  'hedge-fund': '#B2A7E0',
  'other-fund-investments': '#C1B9E6',
  cryptocurrency: '#7FC1A2',
  'other-assets': '#E4C5E9',
  'cash-and-equivalents': '#419074',
  cash: '#419074',
  'cash-equivalents': '#419074',
};
