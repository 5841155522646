import { ASSET_CATEGORY_SLUG_TO_COLOR } from 'domain/AssetCategory/colors';
import {
  AssetCategoryName,
  AssetCategorySlug,
} from 'domain/AssetCategory/constants';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

import { AssetTypeTitles } from '../../Accounts/constants';
import { AssetAllocationCategory } from '../domain/AssetAllocationCategory';

export const CURRENT_ASSET_ALLOCATION_ID = 'CURRENT_ASSET_ALLOCATION_ID';

export type ASSET_CATEGORY = {
  title: string;
  name: AssetCategoryName;
  shortName: string;
  slug: AssetCategorySlug;
  parent: AssetCategorySlug | null;
  color: string;
  index: number;
};

export const ASSET_CATEGORIES: Record<AssetCategorySlug, ASSET_CATEGORY> = {
  'public-assets': {
    title: AssetTypeTitles[AssetTypes.PublicInvestment],
    name: AssetAllocationCategory.name({ slug: 'public-assets' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'public-assets' }),
    slug: 'public-assets',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['public-assets'],
    index: 0,
  },
  equities: {
    title: 'Public Equities',
    name: AssetAllocationCategory.name({ slug: 'equities' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'equities' }),
    slug: 'equities',
    parent: 'public-assets',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['public-equities'],
    index: 1,
  },
  'us-equities': {
    title: 'US Equities',
    name: AssetAllocationCategory.name({ slug: 'us-equities' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'us-equities' }),
    slug: 'us-equities',
    parent: 'equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['us-equities'],
    index: 2,
  },
  'us-equities-large-cap': {
    title: 'Large Cap',
    name: AssetAllocationCategory.name({ slug: 'us-equities-large-cap' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'us-equities-large-cap',
    }),
    slug: 'us-equities-large-cap',
    parent: 'us-equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['us-equities-large-cap'],
    index: 3,
  },
  'us-equities-smallmid-cap': {
    title: 'Small/Mid Cap',
    name: AssetAllocationCategory.name({ slug: 'us-equities-smallmid-cap' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'us-equities-smallmid-cap',
    }),
    slug: 'us-equities-smallmid-cap',
    parent: 'us-equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['us-equities-smallmid-cap'],
    index: 4,
  },
  'us-equities-other': {
    title: 'Other',
    name: AssetAllocationCategory.name({ slug: 'us-equities-other' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'us-equities-other' }),
    slug: 'us-equities-other',
    parent: 'us-equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['us-equities-other'],
    index: 5,
  },
  'international-equities': {
    title: 'International Equities',
    name: AssetAllocationCategory.name({ slug: 'international-equities' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'international-equities',
    }),
    slug: 'international-equities',
    parent: 'equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['international-equities'],
    index: 6,
  },
  'international-equities-developed-ex-us': {
    title: 'Developed (ex-US)',
    name: AssetAllocationCategory.name({
      slug: 'international-equities-developed-ex-us',
    }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'international-equities-developed-ex-us',
    }),
    slug: 'international-equities-developed-ex-us',
    parent: 'international-equities',
    color:
      ASSET_CATEGORY_SLUG_TO_COLOR['international-equities-developed-ex-us'],

    index: 7,
  },
  'international-equities-emerging-markets': {
    title: 'Emerging Markets',
    name: AssetAllocationCategory.name({
      slug: 'international-equities-emerging-markets',
    }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'international-equities-emerging-markets',
    }),
    slug: 'international-equities-emerging-markets',
    parent: 'international-equities',
    color:
      ASSET_CATEGORY_SLUG_TO_COLOR['international-equities-emerging-markets'],
    index: 8,
  },
  'international-equities-other': {
    title: 'Other',
    name: AssetAllocationCategory.name({
      slug: 'international-equities-other',
    }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'international-equities-other',
    }),
    slug: 'international-equities-other',
    parent: 'international-equities',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['international-equities-other'],
    index: 9,
  },
  'fixed-income': {
    title: 'Fixed Income',
    name: AssetAllocationCategory.name({ slug: 'fixed-income' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'fixed-income' }),
    slug: 'fixed-income',
    parent: 'public-assets',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['fixed-income'],
    index: 10,
  },
  'us-fixed-income': {
    title: 'US',
    name: AssetAllocationCategory.name({ slug: 'us-fixed-income' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'us-fixed-income' }),
    slug: 'us-fixed-income',
    parent: 'fixed-income',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['us-fixed-income'],
    index: 11,
  },
  'international-fixed-income': {
    title: 'International',
    name: AssetAllocationCategory.name({ slug: 'international-fixed-income' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'international-fixed-income',
    }),
    slug: 'international-fixed-income',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['international-fixed-income'],
    parent: 'fixed-income',
    index: 12,
  },
  tips: {
    title: 'TIPS',
    name: AssetAllocationCategory.name({ slug: 'tips' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'tips' }),
    slug: 'tips',
    parent: 'fixed-income',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['tips'],
    index: 13,
  },
  'other-fixed-income': {
    title: 'Other',
    name: AssetAllocationCategory.name({ slug: 'other-fixed-income' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'other-fixed-income',
    }),
    slug: 'other-fixed-income',
    parent: 'fixed-income',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['other-fixed-income'],
    index: 14,
  },
  alternatives: {
    title: 'Alternatives',
    name: AssetAllocationCategory.name({ slug: 'alternatives' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'alternatives' }),
    slug: 'alternatives',
    parent: 'public-assets',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['alternatives'],
    index: 15,
  },
  'real-estate-funds': {
    title: 'REITs',
    name: AssetAllocationCategory.name({ slug: 'real-estate-funds' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'real-estate-funds' }),
    slug: 'real-estate-funds',
    parent: 'alternatives',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['real-estate-funds'],
    index: 16,
  },
  'other-alternatives': {
    title: 'Other',
    name: AssetAllocationCategory.name({ slug: 'other-alternatives' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'other-alternatives',
    }),
    slug: 'other-alternatives',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['other-alternatives'],
    parent: 'alternatives',
    index: 17,
  },
  commodities: {
    title: 'Commodities',
    name: AssetAllocationCategory.name({ slug: 'commodities' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'commodities' }),
    slug: 'commodities',
    parent: 'public-assets',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['commodities'],
    index: 18,
  },
  'other-public-assets': {
    title: 'Other Public Assets',
    name: AssetAllocationCategory.name({ slug: 'other-public-assets' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'other-public-assets',
    }),
    slug: 'other-public-assets',
    parent: 'public-assets',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['other-public-assets'],
    index: 19,
  },
  'company-equity': {
    title: 'Company Equity',
    name: AssetAllocationCategory.name({ slug: 'company-equity' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'company-equity' }),
    slug: 'company-equity',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['company-equity'],
    index: 20,
  },
  'real-estate': {
    title: AssetTypeTitles[AssetTypes.RealEstate],
    name: AssetAllocationCategory.name({ slug: 'real-estate' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'real-estate' }),
    slug: 'real-estate',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['real-estate'],
    index: 21,
  },
  'private-investments': {
    title: AssetTypeTitles[AssetTypes.PrivateInvestment],
    name: AssetAllocationCategory.name({ slug: 'private-investments' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'private-investments',
    }),
    slug: 'private-investments',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['private-investments'],
    index: 22,
  },
  'fund-investments': {
    title: AssetTypeTitles[AssetTypes.FundInvestment],
    name: AssetAllocationCategory.name({ slug: 'fund-investments' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'fund-investments' }),
    slug: 'fund-investments',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['fund-investments'],
    index: 23,
  },
  'private-equity-fund': {
    title: 'Private Equity',
    name: AssetAllocationCategory.name({ slug: 'private-equity-fund' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'private-equity-fund',
    }),
    slug: 'private-equity-fund',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['private-equity-fund'],
    index: 24,
  },
  'venture-capital-fund': {
    title: 'Venture Capital',
    name: AssetAllocationCategory.name({ slug: 'venture-capital-fund' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'venture-capital-fund',
    }),
    slug: 'venture-capital-fund',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['venture-capital-fund'],
    index: 25,
  },
  'real-estate-private-fund': {
    title: 'Real Estate',
    name: AssetAllocationCategory.name({ slug: 'real-estate-private-fund' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'real-estate-private-fund',
    }),
    slug: 'real-estate-private-fund',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['real-estate-private-fund'],
    index: 26,
  },
  'private-credit-fund': {
    title: 'Private Credit',
    name: AssetAllocationCategory.name({ slug: 'private-credit-fund' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'private-credit-fund',
    }),
    slug: 'private-credit-fund',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['private-credit-fund'],
    index: 27,
  },
  'hedge-fund': {
    title: 'Hedge Fund',
    name: AssetAllocationCategory.name({ slug: 'hedge-fund' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'hedge-fund' }),
    slug: 'hedge-fund',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['hedge-fund'],
    index: 28,
  },
  'other-fund-investments': {
    title: 'Other',
    name: AssetAllocationCategory.name({ slug: 'other-fund-investments' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'other-fund-investments',
    }),
    slug: 'other-fund-investments',
    parent: 'fund-investments',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['other-fund-investments'],
    index: 29,
  },
  cryptocurrency: {
    title: AssetTypeTitles[AssetTypes.Crypto],
    name: AssetAllocationCategory.name({ slug: 'cryptocurrency' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'cryptocurrency' }),
    slug: 'cryptocurrency',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['cryptocurrency'],
    index: 30,
  },
  'other-assets': {
    title: AssetTypeTitles[AssetTypes.Other],
    name: AssetAllocationCategory.name({ slug: 'other-assets' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'other-assets' }),
    slug: 'other-assets',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['other-assets'],
    index: 31,
  },
  'cash-and-equivalents': {
    title: AssetTypeTitles[AssetTypes.Cash],
    name: AssetAllocationCategory.name({ slug: 'cash-and-equivalents' }),
    shortName: AssetAllocationCategory.shortName({
      slug: 'cash-and-equivalents',
    }),
    slug: 'cash-and-equivalents',
    parent: null,
    color: ASSET_CATEGORY_SLUG_TO_COLOR['cash-and-equivalents'],
    index: 32,
  },
  cash: {
    title: AssetTypeTitles[AssetTypes.Cash],
    name: AssetAllocationCategory.name({ slug: 'cash' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'cash' }),
    slug: 'cash',
    parent: 'cash-and-equivalents',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['cash'],
    index: 33,
  },
  'cash-equivalents': {
    title: AssetTypeTitles[AssetTypes.Cash],
    name: AssetAllocationCategory.name({ slug: 'cash-equivalents' }),
    shortName: AssetAllocationCategory.shortName({ slug: 'cash-equivalents' }),
    slug: 'cash',
    parent: 'cash-and-equivalents',
    color: ASSET_CATEGORY_SLUG_TO_COLOR['cash-equivalents'],
    index: 34,
  },
} as const;

const getChildrenAssetCategories = (assetCategorySlug: AssetCategorySlug) => {
  const children: ASSET_CATEGORY[] = [];
  for (const slug in ASSET_CATEGORIES) {
    if (ASSET_CATEGORIES[slug].parent !== assetCategorySlug) continue;
    children.push(ASSET_CATEGORIES[slug]);
  }
  return children;
};

const getDescendantAssetCategories = (
  assetCategorySlug: AssetCategorySlug,
): ASSET_CATEGORY[] => {
  const children = getChildrenAssetCategories(assetCategorySlug);
  return children.reduce(
    (descendants, child) => [
      ...descendants,
      child,
      ...getDescendantAssetCategories(child.slug),
    ],
    [],
  );
};

export const PUBLIC_ASSETS_CATEGORIES: ASSET_CATEGORY[] = [
  ASSET_CATEGORIES['public-assets'],
  ...getDescendantAssetCategories('public-assets'),
];
export const FUND_INVESTMENTS_CATEGORIES: ASSET_CATEGORY[] = [
  ASSET_CATEGORIES['fund-investments'],
  ...getDescendantAssetCategories('fund-investments'),
];
export const CASH_AND_EQUIVALENTS_CATEGORIES: ASSET_CATEGORY[] = [
  ASSET_CATEGORIES['cash-and-equivalents'],
  ...getDescendantAssetCategories('cash-and-equivalents'),
];

export const TOAST_CONTEXT_ALLOCATION_PROPOSALS = 'allocation-proposals';
