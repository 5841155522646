import pickBy from 'lodash/pickBy';
import merge from 'lodash/merge';
import get from 'lodash/get';
import identity from 'lodash/identity';
import axios, {
  AxiosHeaderValue,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import { API_URL } from './constants';
import { REQUEST_TYPES } from 'utils/constants/axios';
import { AUTHENTICATION_TOKEN_KEY } from 'utils/constants/authentication';

const DEFAULT_HEADER = {
  'Content-Type': 'application/json',
};

export const getAuthHeader = (): {
  [key: string]: AxiosHeaderValue;
} => {
  const authData = JSON.parse(
    localStorage.getItem(AUTHENTICATION_TOKEN_KEY) as string,
  );
  const useJWT =
    window.location.hostname.endsWith('compound-dashboard.netlify.app') ||
    window.location.hostname.endsWith('compound-staging-app.netlify.app') ||
    window.location.hostname.endsWith('compound-app-preview.pages.dev') ||
    window.location.hostname.endsWith('compound-app-preview-staging.pages.dev');
  const auth = get(authData, 'token', null) as string | null;
  const headers: { auth: string } | {} = auth && useJWT ? { auth } : {};
  return merge(DEFAULT_HEADER, headers);
};

export const getAdminHeader = () => {
  return {
    ...getAuthHeader(),
    'admin-auth': true,
  };
};

/**
 * This is a utility function to build GET requests for `useSWR` compatible with
 * endpoints using the `checkIfAdminMiddleware` on the backend. If an `advisorRequestId`
 * is passed in, it will augment the GET request with an advisor JWT and include a
 * query parameter containing the (client) user ID.
 *
 * @param path URL path
 * @param advisorRequestId Pass in a userId here if calling as an advisor.
 * @param queryParams
 * @returns Response from backend
 */
export async function buildGetRequestForSWR<Type = any>(
  path: string,
  queryParams?: Record<string, any>,
) {
  const response = await buildRequest<Type>(
    path,
    true,
    {},
    REQUEST_TYPES.GET,
    queryParams,
  );
  return response.data;
}

/**
 * Wrapper function to handle axios request boilerplate
 * @param url the requested resource
 * @param authRequest Deprecated param - Should this request supply a user's JWT token in a token header? If this is false, useAdminToken should be
 * true, as the supplied auth header will be an admin / advisor header. This property name is confusing as nearly all of the requests made
 * against the compound API require some form of authentication
 * @param body Object supplied in the case of post / update requests
 * @param method Request method. One of the types defined in axios/Methods
 * @param useAdminToken If this is true, an advisor jwt header will be supplied
 * @param additionalParams Query params supplied to request. Optional
 * @param responseType Expected response type. Most are json, but blob can be supplied as well for files
 * @param axiosConfigs Catch-all for any overriding axios configs you might want to supply. This is duplicative with some of the
 * arguments that can be supplied directly to the function, so use with caution. Allows the developer to have more fine-grained
 * control over the request / response life cycle. Optional
 * @param baseUrl The domain to which we are making the request. Pass `""` to make a request to the current domain. Defaults to {@link API_URL}.
 */
export default async function buildRequest<T = any>(
  url: string,
  _authRequest: boolean,
  body: Record<string, any>,
  method: Method | string,
  additionalParams?: Record<string, any>,
  responseType?: 'json' | 'blob',
  axiosConfigs?: AxiosRequestConfig,
  extraHeaders?: Record<string, string>,
  baseUrl: string = API_URL,
) {
  try {
    const request = {
      url: `${baseUrl}${url}`,
      data: body,
      method: method as Method,
      headers: extraHeaders,
      withCredentials: true, // required to set/send cookies across domains
      params: pickBy(additionalParams, identity),
      responseType: responseType || 'json',
      ...(axiosConfigs || {}),
    };
    const data: AxiosResponse<T> = await axios(request);
    return data;
  } catch (e) {
    throw e;
  }
}
