import { LoadingWheel } from '@compoundfinance/design-system';
import { ToastType } from './constants';
import { Error, Info, Success, Warning } from './icons';
import { CompoundCRounded } from 'assets/svg/CompoundC';

interface IconProps {
  type: ToastType;
}

const Icon = (props: IconProps) => {
  const { type } = props;

  switch (type) {
    case ToastType.Info:
      return <Info />;
    case ToastType.Success:
      return <Success />;
    case ToastType.Error:
      return <Error />;
    case ToastType.Warning:
      return <Warning />;
    case ToastType.Promise:
      return <LoadingWheel size="small" />;
    default:
      // Weird number to make the inner rect 22px
      return <CompoundCRounded size={22.69} />;
  }
};

export default Icon;
