// Global reducer - highest level app state

import { ContainerState, ContainerActions } from 'types/reducers/global';
import ActionTypes from '../actionTypes/global';
import {
  DEFAULT_USER_STATE,
  DEFAULT_TAX_PROFILE_STATE,
  DEFAULT_WEALTH_PROFILE,
  DEFAULT_CHART_SETTINGS,
  DEFAULT_FINANCIAL_MANAGER,
  DEFAULT_SPOUSE_STATE,
  DEFAULT_CLIENT_ACCESS_SESSION,
} from '../containers/App/util/constants';

import { CURRENT_TAX_YEAR } from 'utils/constants/tax';
import { CallToAction, OnboardingStepTypes, TaxProfile } from 'types/user';
import { DEFAULT_CLIENT_AGREEMENT } from 'types/clientAgreement';
import { SortMethods } from 'utils/constants/privateInvestments';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import {
  ProductVisibilityKey,
  ProductVisibilitySetting,
} from 'types/productVisibilities';
import { AccountSectionSortOrder } from 'containers/Dashboard/Accounts/constants';
import { replaceReducer } from 'reducers/hmr';
import { Reducers } from 'types/reducers';
import { AdminVisibility } from 'domain/AdminVisibility';

// The initial state of the App
export const initialState: ContainerState = {
  loading: false,
  loggedIn: false,
  loaded: false,
  errorMessage: '',
  user: DEFAULT_USER_STATE,
  sharedUser: null,
  taxProfiles: [],
  activeTaxProfile: DEFAULT_TAX_PROFILE_STATE,
  wealthProfile: DEFAULT_WEALTH_PROFILE,
  clientAgreement: DEFAULT_CLIENT_AGREEMENT,
  files: [],
  userSettings: {
    chartSettings: DEFAULT_CHART_SETTINGS,
    acceptedPrivacyPolicy: null,
    acceptedTerms: null,
    spouse: DEFAULT_SPOUSE_STATE,
    startingBalanceDate: new Date(),
    privateInvestmentHoldingsSort: SortMethods.StatusIssueDate,
    bookmarkSimulations: {},
    timelineFilters: { future: {}, past: {} },
    clientSigned: false,
    activeAccountSections: {} as Record<AssetTypes, boolean>,
    accountSectionSortOrder: AccountSectionSortOrder.NameAscending,
    allocationViewFilters: {} as Record<string, any>,
    taxFormEnabledDate: null,
    lastOnboardingStep: OnboardingStepTypes.Welcome,
    callToActionStatuses: {} as Record<CallToAction, boolean>,
    canContactForFeedback: null,
    stripeCustomerId: null,
    interestedInSelfServeInvest: false,
    interestedInStockOptionFinancing: false,
    referralId: '',
    transactionsAdminVisibility: AdminVisibility.DEFAULT,
  },
  financialAdvisor: DEFAULT_FINANCIAL_MANAGER,
  owners: [],
  clientPartnerships: [],
  // Is CompoundSelect component open. We potentially want to freeze scrolling in the background while true.
  isCompoundSelectOpen: false,
  sessionExpiredModalOpen: false,
  paymentMethods: [],
  hasInvestOrganizer: false,
  showInvestModal: false,
  showCommandMenu: { isOpen: false },
  showBorrowModal: false,
  featureFlags: {},
  advisorFeatureFlags: {},
  productVisibilities: {
    [ProductVisibilityKey.Tax]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.Estate]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.Transactions]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.AdvancedEquityTooling]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.CompoundFlow]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.Documents]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.Services]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.PrivateMarkets]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.SecondaryMarket]: ProductVisibilitySetting.Hide,
    [ProductVisibilityKey.TaxFiling2024]: ProductVisibilitySetting.Hide,
  },
  clientAccessSession: DEFAULT_CLIENT_ACCESS_SESSION,
  taxViewInputs: {
    profileSettings: {
      ...DEFAULT_TAX_PROFILE_STATE,
      stClCredits: 0,
      ltClCredits: 0,
    },
    companies: [],
  },
  //@ts-ignore
  taxViewOutputs: {},
  taxFilingIntros: {},
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SET_AUTH:
      return {
        ...state,
        user: action.payload.user,
        loggedIn: true,
        errorMessage: '',
      };
    case ActionTypes.SET_TWO_FACTOR:
      return {
        ...state,
        twoFactor: action.payload.twoFactor,
      };
    case ActionTypes.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        errorMessage: '',
      };
    case ActionTypes.UPDATE_ACTIVE_TAX_PROFILE:
      const nextActiveTaxProfile = {
        ...state.activeTaxProfile,
        ...action.payload.taxProfile,
      };

      const activeTaxProfileIndex = state.taxProfiles.findIndex(
        (tp) => tp.id === nextActiveTaxProfile.id,
      );
      const nextTaxProfiles = state.taxProfiles.slice();
      nextTaxProfiles.splice(activeTaxProfileIndex, 1, nextActiveTaxProfile);

      return {
        ...state,
        taxProfiles: nextTaxProfiles,
        activeTaxProfile: nextActiveTaxProfile,
        errorMessage: '',
      };
    case ActionTypes.UPDATE_WEALTH_PROFILE:
      return {
        ...state,
        wealthProfile: {
          ...state.wealthProfile,
          ...action.payload.wealthProfile,
        },
        errorMessage: '',
      };
    case ActionTypes.SetClientAgreement:
      return {
        ...state,
        clientAgreement: { ...action.payload.clientAgreement, loaded: true },
        errorMessage: '',
      };
    case ActionTypes.UpdateClientAgreement:
      return {
        ...state,
        clientAgreement: action.payload.clientAgreement,
        errorMessage: '',
      };
    case ActionTypes.UPDATE_GLOBAL_STATE:
      return {
        ...state,
        ...action.payload.state,
      };
    case ActionTypes.UpdateLastOnboardingStep:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          lastOnboardingStep: action.payload.step,
        },
      };
    case ActionTypes.AcceptToS:
      // These dates aren't exactly the same as in database but we don't need such an accuracy anywhere
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          acceptedTerms: new Date(),
          acceptedPrivacyPolicy: new Date(),
        },
      };
    case ActionTypes.UpdateUserSettings:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          ...action.payload.userSettings,
        },
      };
    case ActionTypes.UpdateOwnerState: {
      return {
        ...state,
        owners: action.payload.owners,
      };
    }
    case ActionTypes.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    case ActionTypes.SetTaxProfiles: {
      const { taxProfiles } = action.payload;
      const activeProfile = taxProfiles.find(
        (tp) => tp.year === CURRENT_TAX_YEAR,
      ) as TaxProfile;

      return {
        ...state,
        activeTaxProfile: activeProfile,
        taxProfiles,
      };
    }
    case ActionTypes.UpdateAddress:
      return {
        ...state,
        user: {
          ...state.user,
          address: { ...state.user.address, ...action.payload.address },
        },
      };
    case ActionTypes.UpdateActiveAccountSections:
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          activeAccountSections: action.payload.activeAccountSections || {},
        },
      };
    case ActionTypes.SetSessionExpiredModalOpen:
      return { ...state, sessionExpiredModalOpen: action.payload };
    case ActionTypes.UpdateProductVisibility:
      return {
        ...state,
        productVisibilities: {
          ...state.productVisibilities,
          [action.payload.key]: action.payload.value,
        },
      };
    case ActionTypes.UpdateTaxViewSettings:
      return {
        ...state,
        taxViewInputs: {
          ...state.taxViewInputs,
          ...action.payload.update,
        },
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.GLOBAL));
}

export default appReducer;
