import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { useEffect, useRef } from 'react';
import { getAdminHeader, getAuthHeader } from 'utils/api';
import { API_URL } from 'utils/api/constants';
import useTypedSelector from './typedSelector';
import useIsClient from './useIsClient';
import { omit } from 'lodash';
import useSearchParams from './useSearchParams';
import useIsAdvisor from './advisor/useIsAdvisor';
import useIsPartner from './useIsPartner';
import { UserRoleType } from 'utils/constants/user';

/**
 * Disclaimer:
 * I'm afraid this might not be concurrent-mode safe; although if it wasn't, the impact should be fairly low.
 * The reason we decided to set the interceptors during `render` is because doing it in an `effect` would,
 * in some occasions, be too late, since `child`'s effects are run earlier than parents'.
 */

const useClientDashboardId = () => {
  const {
    searchParams: { clientId: clientIdFromParams },
  } = useSearchParams();

  const user = useTypedSelector((state) => state.global.user);

  const userId = user?.id;
  const userRole = user?.role;

  if (userRole === UserRoleType.Default) {
    return userId;
  }

  return clientIdFromParams;
};

export function useForwardUserId() {
  const isAdvisor = useIsAdvisor();
  const isPartner = useIsPartner();

  const isAdmin = isAdvisor || isPartner;
  const shouldForwardUserId = isAdmin;

  const clientId = useClientDashboardId();

  useEffect(() => {
    if (!shouldForwardUserId) return;
    if (!clientId) return;

    const axiosInterceptorId = axios.interceptors.request.use(
      (requestConfig: InternalAxiosRequestConfig<any>) => {
        const { params = {}, url = '' } = requestConfig;
        const isRequestToOurAPI = url.includes(API_URL);
        if (params.noForwardedId) {
          return { ...requestConfig, params: omit(params, 'noForwardedId') };
        }
        if (!isRequestToOurAPI) return requestConfig;
        return {
          ...requestConfig,
          params: {
            userId: clientId,
            /** Notice we leave the option open for overwriting the `userId` by setting your own from the request handler */
            ...requestConfig?.params,
          },
        };
      },
    );

    return () => {
      if (axiosInterceptorId) {
        axios.interceptors.request.eject(axiosInterceptorId);
      }
    };
  }, [shouldForwardUserId, clientId]);
}

export function useAuthentication() {
  const isClient = useIsClient();

  const interceptorIdRef = useRef<number>();

  if (interceptorIdRef.current) {
    axios.interceptors.request.eject(interceptorIdRef.current);
  }

  interceptorIdRef.current = axios.interceptors.request.use(
    (requestConfig: InternalAxiosRequestConfig<any>) => {
      const { headers = new AxiosHeaders(), url = '' } = requestConfig;
      const isRequestToOurAPI = url.includes(API_URL);
      if (!isRequestToOurAPI) return requestConfig;

      const additionalHeaders = new AxiosHeaders(
        isClient ? getAuthHeader() : getAdminHeader(),
      );

      return {
        ...requestConfig,
        // concat the original headers on to the `additionalHeaders` so the original values win.
        headers: additionalHeaders.concat(headers),
      };
    },
  );
}
