import { FundInvestment, GPExposure, LPExposure } from 'types/fundInvestments';
import { PrivateInvestment } from 'types/equity';
import {
  DefiAccount,
  InvestmentHolding,
  NFT,
  PlaidAccount,
} from '@compoundfinance/compound-core/dist/types/plaid';
import {
  AccountSnapshot,
  AssetTypes,
  Ownership,
} from '@compoundfinance/compound-core/dist/types/account';
import { ArgyleEmployment } from 'shared/argyle/types';
import { ValueOf } from 'types';
import { CashflowHomePaydownMethod } from 'types/cashflow';
import { InterestRateType } from 'utils/plaid/constants';
import { PrivateEquityAccount } from '@compoundfinance/compound-core/dist/types/equity';

export enum AssetValueTypes {
  Total = 'total',
  PerUnit = 'perUnit',
}

export const LoanSubtypes = {
  Auto: 'auto',
  Commercial: 'commercial',
  Consumer: 'consumer',
  HomeEquity: 'home equity',
  General: 'general',
  Mortgage: 'mortgage',
  LineOfCredit: 'line of credit',
  Student: 'student',
  Other: 'other',
} as const;
export type LoanSubtypes = ValueOf<typeof LoanSubtypes>;

export enum AssetFields {
  Name = 'name',
  Quantity = 'quantity',
  Value = 'value',
  Notes = 'notes',
  Projections = 'projections',
  PurchasePrice = 'purchasePrice',
  ResidenceState = 'residenceState',
  CurrentValue = 'currentValue',
  FundType = 'type',
  Address = 'address',
  ValuationType = 'valuationType',
  Nickname = 'nickname',
  IsRental = 'isRental',
  RentalIncome = 'rentalIncome',
  AnnualPropertyTaxRate = 'annualPropertyTaxRate',
  PropertyTaxSchedule = 'propertyTaxSchedule',
  AnnualMaintenanceCost = 'annualMaintenanceCost',
  PurchaseDate = 'purchaseDate',
}

export enum LoanFields {
  OfficialName = 'officialName',
  Nickname = 'nickname',
  CurrentBalance = 'currentBalance',
  OriginalBalance = 'originalBalance',
  Date = 'date',
  Duration = 'duration',
  AnnualInterest = 'annualInterest',
  Type = 'type',
  MortgageType = 'mortgageType',
  InterestRateType = 'interestRateType',
  HasPrepaymentPenalty = 'hasPrepaymentPenalty',
  MonthlyPaymentAmount = 'monthlyPaymentAmount',
}

enum ProjectionScenarios {
  Bad = 'bad',
  Neutral = 'neutral',
  Positive = 'positive',
}

interface LoanProjections {
  remainingBalance: number | null;
  totalInterestPaid: number | null;
}

interface Projections {
  [ProjectionScenarios.Bad]: number | null;
  [ProjectionScenarios.Neutral]: number | null;
  [ProjectionScenarios.Positive]: number | null;
}

interface AssetMetadata {
  notes: string;
  projections: Projections;
}

interface LoanMetadata {
  notes: string;
  projections: LoanProjections;
}

interface AssetPersistenceData {
  id?: string;
  userId?: string;
  ownerId?: string;
  ownership?: Ownership;
  createdAt?: Date;
  updatedAt?: Date;
  accountSnapshots: AccountSnapshot[];
}

type AssetWithMetadata<I> = (I & AssetMetadata) | (I & LoanMetadata);
// Generic type for proving valid typing for arbitrary assets
export type AssetFormValues<I> = AssetWithMetadata<I> &
  AssetPersistenceData & { assetType: AssetTypes };

export interface OtherAsset {
  returnRate?: number;
  name: string;
  quantity: number | null;
  value: number | null;
  actualValue?: number | null;
}

export enum PropertyTaxScheduleType {
  Annual = 'annual',
  Monthly = 'monthly',
}

export interface RealEstate {
  id?: string;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  residenceState: string | null;
  name: string;
  purchasePrice: number | null;
  value: number | null;
  valuationType: RealEstateValuationType;
  nickname: string | null;
  actualValue?: number | null;

  isRental: boolean;
  rentalIncome: number | null;
  annualPropertyTaxRate: number | null;
  propertyTaxSchedule: PropertyTaxScheduleType;
  annualMaintenanceCost: number | null;
  purchaseDate: Date | null;
}

export interface Loan {
  id?: string;
  officialName: string;
  nickname: string;
  currentBalance: number | null;
  actualCurrentBalance?: number | null;
  originalBalance: number | null;
  date: Date | null;
  // number in years
  duration: number | null;
  annualInterest: number | null;
  type: LoanSubtypes;
  mortgageType: CashflowHomePaydownMethod | null;
  interestRateType: InterestRateType | null;
  hasPrepaymentPenalty: boolean | null;
  monthlyPaymentAmount: number | null;
}

// New Asset types should be added to here so the compiler treats them as a
// CompoundAsset
export type CompoundAsset = AssetFormValues<
  | InvestmentHolding
  | RealEstate
  | OtherAsset
  | FundInvestment
  | LPExposure
  | GPExposure
  | PrivateInvestment
  | Loan
  | PrivateEquityAccount
  | PlaidAccount
  | ArgyleEmployment
  | DefiAccount
  | NFT
>;

// 2022/06/09 This is also used on backend models/realEstate.ts
export enum RealEstateValuationType {
  automatic = 'automatic',
  manual = 'manual',
}
