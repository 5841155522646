export enum SplitFeature {
  ENABLE_FAMILY_OFFICE = 'enable_family_office',
  ENABLE_EQUITY_VALUATION_METHODS = 'enable_frontend_equity_valuation_method',
  ALLOW_EDITING_STRIPE_CUSTOMER_ID = 'allow_editing_stripe_customer_id',
  DISABLE_CARTA_INTEGRATION = 'disable_carta_integration',
  ENABLE_ADD_ACCOUNT_ETH_WALLET = 'enable_add_account_eth_wallet',
  ENABLE_PRIVATE_MARKETS = 'enable_private_markets',
  ENABLE_EQUITY_MODELING = 'enable_frontend_equity_modeling',
  ENABLE_NEW_REAL_ESTATE = 'enable_new_real_estate',
  ENABLE_SUPERBLOCKS_ADMIN_LINK = 'enable_superblocks_authentication',
  ENABLE_ADD_ACCOUNT_ARGYLE = 'enable_add_account_argyle',
  TRANSACTIONS = 'transactions',
  ENABLE_DOCUMENT_MAGIC_LINKS = 'enable_document_magic_links',
  ENABLE_LIABILITIES_SECTION = 'enable_liabilities_section',
  ENABLE_RECATEGORIZING_SIDEBAR_SECTIONS = 'enable_recategorizing_sidebar_sections',
  ENABLE_EQUITY_MODELING_REMAINING_SHARES = 'enable_equity_modeling_remaining_shares',
  HIDE_ESTATE_PLANNING = 'hide_estate_planning',
  ENABLE_VAULT_FILTERING_BY_USER = 'enable_vault_filtering_by_user',
  HIDE_BORROW = 'hide_borrow',
  ENABLE_ACCOUNT_DATA_MIGRATION_MODAL = 'enable_account_data_migration_modal',
  ENABLE_PV_ADJUSTING_FOR_MONTE_CARLO = 'enable_pv_adjusting_for_monte_carlo',
  ENABLE_SERVICES_TAB = 'enable_services_tab',
  ENABLE_EQUITY_TOOLS = 'enable_equity_tools',
  ENABLE_NEW_HOMEPAGE = 'enable_new_homepage',
  ENABLE_2024_TAX_FILING = 'enable_2024_tax_filing',
  ENABLE_WITHDRAWAL_ORDER = 'enable_withdrawal_order',
}
