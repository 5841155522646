enum ActionTypes {
  UPDATE_USER_REQUEST = 'SETTINGS/UPDATE_USER_REQUEST',
  UPDATE_TAX_PROFILE_REQUEST = 'SETTINGS/UPDATE_TAX_PROFILE_REQUEST',
  UPDATE_WEALTH_PROFILE_REQUEST = 'SETTINGS/UPDATE_WEALTH_PROFILE_REQUEST',
  START_REQUEST = 'SETTINGS/START_REQUEST',
  COMPLETE_REQUEST = 'SETTINGS/COMPLETE_REQUEST',
  DEFAULT_ACTION = 'SETTINGS/DEFAULT_ACTION',
  CHECK_IF_AUTHED = 'SETTINGS/CHECK_IF_AUTHED',
  UPDATE_EQUITY_STATE_FIELD = 'SETTINGS/UPDATE_EQUITY_STATE_FIELD',
  UPDATE_EQUITY_STATE = 'SETTINGS/UPDATE_EQUITY_STATE',
  UpdateUserSettingsRequest = 'Settings/UpdateUserSettingsRequest',
  UpdateUserSettingsLocallyRequest = 'Settings/UpdateUserSettingsLocallyRequest',
  ResyncCompoundPortfolioRequest = 'Settings/ResyncCompoundPortfolioRequest',
  UpdateClientAgreementRequest = 'Settings/UpdateClientAgreementRequest',
  UpdateProductVisibilityRequest = 'Settings/UpdateProductVisibilityRequest',
  UpdateAddressRequest = 'Settings/UpdateAddressRequest',
  UpdateInterestedInSelfServeInvest = 'Settings/UpdateInterestedInSelfServeInvest',
  UpdateInterestedInStockOptionFinancing = 'Settings/UpdateInterestedInStockOptionFinancing',
}

export default ActionTypes;
