import { NumberUtils } from '../../../utils/NumberUtils';
import {
  ASSET_CATEGORY_NAME_TO_SLUG,
  AssetCategorySlug,
} from 'domain/AssetCategory/constants';

type AssetClassSlug = Extract<
  AssetCategorySlug,
  | 'us-equities-large-cap'
  | 'us-equities-smallmid-cap'
  | 'international-equities-developed-ex-us'
  | 'international-equities-emerging-markets'
  | 'us-fixed-income'
  | 'international-fixed-income'
  | 'tips'
  | 'real-estate-funds'
  | 'commodities'
  | 'private-equity-fund'
  | 'venture-capital-fund'
  | 'real-estate-private-fund'
  | 'private-credit-fund'
  | 'hedge-fund'
  | 'cash'
  | 'cash-equivalents'
>;

const usEquitiesLargeCap =
  ASSET_CATEGORY_NAME_TO_SLUG['US Equities: Large Cap'];
const usEquitiesSmallMidCap =
  ASSET_CATEGORY_NAME_TO_SLUG['US Equities: Small/Mid Cap'];
const internationalEquitiesDevelopedExUS =
  ASSET_CATEGORY_NAME_TO_SLUG['International Equities: Developed (ex-US)'];
const internationalEquitiesEmergingMarkets =
  ASSET_CATEGORY_NAME_TO_SLUG['International Equities: Emerging Markets'];
const usFixedIncome = ASSET_CATEGORY_NAME_TO_SLUG['US Fixed Income'];
const internationalFixedIncome =
  ASSET_CATEGORY_NAME_TO_SLUG['International Fixed Income'];
const tips = ASSET_CATEGORY_NAME_TO_SLUG['TIPS'];
const reits = ASSET_CATEGORY_NAME_TO_SLUG['Real Estate Funds'];
const commodities = ASSET_CATEGORY_NAME_TO_SLUG['Commodities'];
const privateEquityFund = ASSET_CATEGORY_NAME_TO_SLUG['Private Equity Fund'];
const ventureCapitalFund = ASSET_CATEGORY_NAME_TO_SLUG['Venture Capital Fund'];
const realEstatePrivateFund =
  ASSET_CATEGORY_NAME_TO_SLUG['Real Estate Private Fund'];
const privateCreditFund = ASSET_CATEGORY_NAME_TO_SLUG['Private Credit Fund'];
const hedgeFund = ASSET_CATEGORY_NAME_TO_SLUG['Hedge Fund'];
const cashEquivalents = ASSET_CATEGORY_NAME_TO_SLUG['Cash Equivalents'];
const cash = ASSET_CATEGORY_NAME_TO_SLUG['Cash'];

/**
 * Data extracted from [this Spredsheet](https://docs.google.com/spreadsheets/d/1gYl5kCjf6m9Y59re8t_nGZ65rME_ngclJtpwVXuZP1Y/edit#gid=1699081697).
 */
// prettier-ignore
export const ASSET_CATEGORIES_COVARIANCE: Record<
  AssetClassSlug,
  Record<AssetClassSlug, number>
> = {
  [usEquitiesLargeCap]: {
    [usEquitiesLargeCap]: 2.60,
    [usEquitiesSmallMidCap]: 2.99,
    [internationalEquitiesDevelopedExUS]: 2.50,
    [internationalEquitiesEmergingMarkets]: 2.53,
    [usFixedIncome]: 0.13,
    [internationalFixedIncome]: 0.45,
    [tips]: 0.23,
    [reits]: 1.94,
    [commodities]: 1.12,
    [privateEquityFund]: 2.48,
    [ventureCapitalFund]: 2.33,
    [realEstatePrivateFund]: 1.05,
    [privateCreditFund]: 1.32,
    [hedgeFund]: 0.79,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [usEquitiesSmallMidCap]: {
    [usEquitiesLargeCap]: 2.99,
    [usEquitiesSmallMidCap]: 4.15,
    [internationalEquitiesDevelopedExUS]: 2.87,
    [internationalEquitiesEmergingMarkets]: 2.99,
    [usFixedIncome]: 0.12,
    [internationalFixedIncome]: 0.45,
    [tips]: 0.22,
    [reits]: 2.36,
    [commodities]: 1.20,
    [privateEquityFund]: 3.01,
    [ventureCapitalFund]: 3.21,
    [realEstatePrivateFund]: 1.13,
    [privateCreditFund]: 1.67,
    [hedgeFund]: 0.93,
    [cash]: -0.01,
    [cashEquivalents]: -0.01,
  },
  [internationalEquitiesDevelopedExUS]: {
    [usEquitiesLargeCap]: 2.50,
    [usEquitiesSmallMidCap]: 2.87,
    [internationalEquitiesDevelopedExUS]: 3.03,
    [internationalEquitiesEmergingMarkets]: 3.13,
    [usFixedIncome]: 0.16,
    [internationalFixedIncome]: 0.67,
    [tips]: 0.27,
    [reits]: 1.90,
    [commodities]: 1.49,
    [privateEquityFund]: 2.78,
    [ventureCapitalFund]: 2.35,
    [realEstatePrivateFund]: 1.02,
    [privateCreditFund]: 1.38,
    [hedgeFund]: 0.86,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [internationalEquitiesEmergingMarkets]: {
    [usEquitiesLargeCap]: 2.53,
    [usEquitiesSmallMidCap]: 2.99,
    [internationalEquitiesDevelopedExUS]: 3.13,
    [internationalEquitiesEmergingMarkets]: 4.26,
    [usFixedIncome]: 0.19,
    [internationalFixedIncome]: 0.77,
    [tips]: 0.36,
    [reits]: 1.86,
    [commodities]: 1.92,
    [privateEquityFund]: 3.30,
    [ventureCapitalFund]: 2.84,
    [realEstatePrivateFund]: 1.08,
    [privateCreditFund]: 1.66,
    [hedgeFund]: 0.98,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [usFixedIncome]: {
    [usEquitiesLargeCap]: 0.13,
    [usEquitiesSmallMidCap]: 0.12,
    [internationalEquitiesDevelopedExUS]: 0.16,
    [internationalEquitiesEmergingMarkets]: 0.19,
    [usFixedIncome]: 0.17,
    [internationalFixedIncome]: 0.25,
    [tips]: 0.16,
    [reits]: 0.18,
    [commodities]: -0.12,
    [privateEquityFund]: -0.12,
    [ventureCapitalFund]: -0.19,
    [realEstatePrivateFund]: -0.14,
    [privateCreditFund]: -0.08,
    [hedgeFund]: 0.01,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [internationalFixedIncome]: {
    [usEquitiesLargeCap]: 0.45,
    [usEquitiesSmallMidCap]: 0.45,
    [internationalEquitiesDevelopedExUS]: 0.67,
    [internationalEquitiesEmergingMarkets]: 0.77,
    [usFixedIncome]: 0.25,
    [internationalFixedIncome]: 0.72,
    [tips]: 0.30,
    [reits]: 0.43,
    [commodities]: 0.29,
    [privateEquityFund]: 0.20,
    [ventureCapitalFund]: -0.04,
    [realEstatePrivateFund]: -0.22,
    [privateCreditFund]: -0.08,
    [hedgeFund]: 0.08,
    [cash]: 0.01,
    [cashEquivalents]: 0.01,
  },
  [tips]: {
    [usEquitiesLargeCap]: 0.23,
    [usEquitiesSmallMidCap]: 0.22,
    [internationalEquitiesDevelopedExUS]: 0.27,
    [internationalEquitiesEmergingMarkets]: 0.36,
    [usFixedIncome]: 0.16,
    [internationalFixedIncome]: 0.30,
    [tips]: 0.31,
    [reits]: 0.27,
    [commodities]: 0.20,
    [privateEquityFund]: 0.11,
    [ventureCapitalFund]: 0.03,
    [realEstatePrivateFund]: 0.03,
    [privateCreditFund]: 0.11,
    [hedgeFund]: 0.09,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [reits]: {
    [usEquitiesLargeCap]: 1.94,
    [usEquitiesSmallMidCap]: 2.36,
    [internationalEquitiesDevelopedExUS]: 1.90,
    [internationalEquitiesEmergingMarkets]: 1.86,
    [usFixedIncome]: 0.18,
    [internationalFixedIncome]: 0.43,
    [tips]: 0.27,
    [reits]: 2.51,
    [commodities]: 0.74,
    [privateEquityFund]: 1.61,
    [ventureCapitalFund]: 1.14,
    [realEstatePrivateFund]: 1.33,
    [privateCreditFund]: 0.91,
    [hedgeFund]: 0.49,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [commodities]: {
    [usEquitiesLargeCap]: 1.12,
    [usEquitiesSmallMidCap]: 1.20,
    [internationalEquitiesDevelopedExUS]: 1.49,
    [internationalEquitiesEmergingMarkets]: 1.92,
    [usFixedIncome]: -0.12,
    [internationalFixedIncome]: 0.29,
    [tips]: 0.20,
    [reits]: 0.74,
    [commodities]: 3.20,
    [privateEquityFund]: 2.14,
    [ventureCapitalFund]: 1.65,
    [realEstatePrivateFund]: 1.05,
    [privateCreditFund]: 1.29,
    [hedgeFund]: 0.59,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [privateEquityFund]: {
    [usEquitiesLargeCap]: 2.48,
    [usEquitiesSmallMidCap]: 3.01,
    [internationalEquitiesDevelopedExUS]: 2.78,
    [internationalEquitiesEmergingMarkets]: 3.30,
    [usFixedIncome]: -0.12,
    [internationalFixedIncome]: 0.20,
    [tips]: 0.11,
    [reits]: 1.61,
    [commodities]: 2.14,
    [privateEquityFund]: 3.98,
    [ventureCapitalFund]: 3.51,
    [realEstatePrivateFund]: 1.33,
    [privateCreditFund]: 2.07,
    [hedgeFund]: 1.07,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [ventureCapitalFund]: {
    [usEquitiesLargeCap]: 2.33,
    [usEquitiesSmallMidCap]: 3.21,
    [internationalEquitiesDevelopedExUS]: 2.35,
    [internationalEquitiesEmergingMarkets]: 2.84,
    [usFixedIncome]: -0.19,
    [internationalFixedIncome]: -0.04,
    [tips]: 0.03,
    [reits]: 1.14,
    [commodities]: 1.65,
    [privateEquityFund]: 3.51,
    [ventureCapitalFund]: 5.08,
    [realEstatePrivateFund]: 0.93,
    [privateCreditFund]: 1.75,
    [hedgeFund]: 1.10,
    [cash]: -0.01,
    [cashEquivalents]: -0.01,
  },
  [realEstatePrivateFund]: {
    [usEquitiesLargeCap]: 1.05,
    [usEquitiesSmallMidCap]: 1.13,
    [internationalEquitiesDevelopedExUS]: 1.02,
    [internationalEquitiesEmergingMarkets]: 1.08,
    [usFixedIncome]: -0.14,
    [internationalFixedIncome]: -0.22,
    [tips]: 0.03,
    [reits]: 1.33,
    [commodities]: 1.05,
    [privateEquityFund]: 1.33,
    [ventureCapitalFund]: 0.93,
    [realEstatePrivateFund]: 2.50,
    [privateCreditFund]: 0.99,
    [hedgeFund]: 0.40,
    [cash]: -0.01,
    [cashEquivalents]: -0.01,
  },
  [privateCreditFund]: {
    [usEquitiesLargeCap]: 1.32,
    [usEquitiesSmallMidCap]: 1.67,
    [internationalEquitiesDevelopedExUS]: 1.38,
    [internationalEquitiesEmergingMarkets]: 1.66,
    [usFixedIncome]: -0.08,
    [internationalFixedIncome]: -0.08,
    [tips]: 0.11,
    [reits]: 0.91,
    [commodities]: 1.29,
    [privateEquityFund]: 2.07,
    [ventureCapitalFund]: 1.75,
    [realEstatePrivateFund]: 0.99,
    [privateCreditFund]: 1.86,
    [hedgeFund]: 0.60,
    [cash]: -0.01,
    [cashEquivalents]: -0.01,
  },
  [hedgeFund]: {
    [usEquitiesLargeCap]: 0.79,
    [usEquitiesSmallMidCap]: 0.93,
    [internationalEquitiesDevelopedExUS]: 0.86,
    [internationalEquitiesEmergingMarkets]: 0.98,
    [usFixedIncome]: 0.01,
    [internationalFixedIncome]: 0.08,
    [tips]: 0.09,
    [reits]: 0.49,
    [commodities]: 0.59,
    [privateEquityFund]: 1.07,
    [ventureCapitalFund]: 1.10,
    [realEstatePrivateFund]: 0.40,
    [privateCreditFund]: 0.60,
    [hedgeFund]: 0.47,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [cash]: {
    [usEquitiesLargeCap]: 0.00,
    [usEquitiesSmallMidCap]: -0.01,
    [internationalEquitiesDevelopedExUS]: 0.00,
    [internationalEquitiesEmergingMarkets]: 0.00,
    [usFixedIncome]: 0.00,
    [internationalFixedIncome]: 0.01,
    [tips]: 0.00,
    [reits]: 0.00,
    [commodities]: 0.00,
    [privateEquityFund]: 0.00,
    [ventureCapitalFund]: -0.01,
    [realEstatePrivateFund]: -0.01,
    [privateCreditFund]: -0.01,
    [hedgeFund]: 0.00,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
  [cashEquivalents]: {
    [usEquitiesLargeCap]: 0.00,
    [usEquitiesSmallMidCap]: -0.01,
    [internationalEquitiesDevelopedExUS]: 0.00,
    [internationalEquitiesEmergingMarkets]: 0.00,
    [usFixedIncome]: 0.00,
    [internationalFixedIncome]: 0.01,
    [tips]: 0.00,
    [reits]: 0.00,
    [commodities]: 0.00,
    [privateEquityFund]: 0.00,
    [ventureCapitalFund]: -0.01,
    [realEstatePrivateFund]: -0.01,
    [privateCreditFund]: -0.01,
    [hedgeFund]: 0.00,
    [cash]: 0.00,
    [cashEquivalents]: 0.00,
  },
} as const;

export const ASSET_CATEGORIES_COVARIANCE_MATRIX = Object.values(
  ASSET_CATEGORIES_COVARIANCE,
).map((assetCategoryCovariance) =>
  Object.values(assetCategoryCovariance).map((assetCategoryCovariance) =>
    NumberUtils.divide(assetCategoryCovariance, 100),
  ),
);

export const ASSET_CATEGORIES_RETURN_AND_VOLATILITY: Record<
  AssetClassSlug,
  Record<'annualReturn' | 'annualVolatility', number>
> = {
  [usEquitiesLargeCap]: {
    annualReturn: 7.9,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[usEquitiesLargeCap][usEquitiesLargeCap],
      ) * 10,
  },
  [usEquitiesSmallMidCap]: {
    annualReturn: 8.1,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[usEquitiesSmallMidCap][
          usEquitiesSmallMidCap
        ],
      ) * 10,
  },
  [internationalEquitiesDevelopedExUS]: {
    annualReturn: 9.8,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[internationalEquitiesDevelopedExUS][
          internationalEquitiesDevelopedExUS
        ],
      ) * 10,
  },
  [internationalEquitiesEmergingMarkets]: {
    annualReturn: 10.1,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[internationalEquitiesEmergingMarkets][
          internationalEquitiesEmergingMarkets
        ],
      ) * 10,
  },
  [usFixedIncome]: {
    annualReturn: 4.6,
    annualVolatility:
      Math.sqrt(ASSET_CATEGORIES_COVARIANCE[usFixedIncome][usFixedIncome]) * 10,
  },
  [internationalFixedIncome]: {
    annualReturn: 4.6,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[internationalFixedIncome][
          internationalFixedIncome
        ],
      ) * 10,
  },
  [tips]: {
    annualReturn: 4.3,
    annualVolatility: Math.sqrt(ASSET_CATEGORIES_COVARIANCE[tips][tips]) * 10,
  },
  [reits]: {
    annualReturn: 6.8,
    annualVolatility: Math.sqrt(ASSET_CATEGORIES_COVARIANCE[reits][reits]) * 10,
  },
  [commodities]: {
    annualReturn: 3.1,
    annualVolatility:
      Math.sqrt(ASSET_CATEGORIES_COVARIANCE[commodities][commodities]) * 10,
  },
  [privateEquityFund]: {
    annualReturn: 9.9,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[privateEquityFund][privateEquityFund],
      ) * 10,
  },
  [ventureCapitalFund]: {
    annualReturn: 8.5,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[ventureCapitalFund][ventureCapitalFund],
      ) * 10,
  },
  [realEstatePrivateFund]: {
    annualReturn: 7.2,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[realEstatePrivateFund][
          realEstatePrivateFund
        ],
      ) * 10,
  },
  [privateCreditFund]: {
    annualReturn: 7.8,
    annualVolatility:
      Math.sqrt(
        ASSET_CATEGORIES_COVARIANCE[privateCreditFund][privateCreditFund],
      ) * 10,
  },
  [hedgeFund]: {
    annualReturn: 5.0,
    annualVolatility:
      Math.sqrt(ASSET_CATEGORIES_COVARIANCE[hedgeFund][hedgeFund]) * 10,
  },
  [cash]: {
    annualReturn: 2.4,
    annualVolatility: Math.sqrt(ASSET_CATEGORIES_COVARIANCE[cash][cash]) * 10,
  },
  [cashEquivalents]: {
    annualReturn: 2.4,
    annualVolatility:
      Math.sqrt(ASSET_CATEGORIES_COVARIANCE[cashEquivalents][cashEquivalents]) *
      10,
  },
} as const;
