import { isEmpty, pickBy, identity } from 'lodash';
import { API_URL } from 'utils/api/constants';
import { RedirectState } from './types';

export const getLoginUrl = (state: RedirectState) => {
  const options = pickBy(
    {
      state: !isEmpty(state) ? JSON.stringify(state) : undefined,
      invitation_code: state.invitation_code,
      request_access_id: state.request_access_id,
      referral_code: state.referral_code,
    },
    identity,
  ) as Record<string, string>;

  const queryString = new URLSearchParams(options).toString();
  return `${API_URL}/api/auth0/login?${queryString}`;
};
