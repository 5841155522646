import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';

import { InvestmentHolding } from '@compoundfinance/compound-core/dist/types/plaid';
import { ValueOf } from 'types';
import { TaxTreatment } from '../AllocationProposals/domain/InvestmentTarget';

const SubTypes = {
  Brokerage: 'brokerage',
  BrokerageAccounts: 'brokerage accounts',
  CharitableGiving: 'charitable giving',
  ContributoryIRA: 'contributory ira',
  Corporation: 'corporation',
  Education: 'education',
  Four01k: '401k',
  GeneralSavings: 'general savings',
  InvestmentAccounts: 'investment accounts',
  IRA: 'ira',
  IRAAccounts: 'ira accounts',
  JointInvestment: 'joint investment',
  LimitedLiabilityCompany: 'limited liability company',
  LivingTrust: 'living trust',
  NonProfitGiving: 'non-profit giving',
  Other: 'other',
  RetirementAccounts: 'retirement accounts',
  Roth: 'roth',
  Savings: 'savings',
  SepIra: 'sep ira',
  SimpleIRA: 'simple ira',
  TenantsInCommon: 'tenants in common',
  TrustAccounts: 'trust accounts',
} as const;
type CompoundInvestSubTypes = ValueOf<typeof SubTypes>;

const SubTypeLabels = {
  [SubTypes.Brokerage]: 'Brokerage',
  [SubTypes.BrokerageAccounts]: 'Brokerage accounts',
  [SubTypes.CharitableGiving]: 'Charitable giving',
  [SubTypes.ContributoryIRA]: 'Contributory IRA',
  [SubTypes.Corporation]: 'Corporation',
  [SubTypes.Education]: 'Education',
  [SubTypes.Four01k]: '401k',
  [SubTypes.GeneralSavings]: 'General savings',
  [SubTypes.InvestmentAccounts]: 'Investment accounts',
  [SubTypes.IRA]: 'IRA',
  [SubTypes.IRAAccounts]: 'IRA Accounts',
  [SubTypes.JointInvestment]: 'Joint investment',
  [SubTypes.LimitedLiabilityCompany]: 'Limited liability company',
  [SubTypes.LivingTrust]: 'Living trust',
  [SubTypes.NonProfitGiving]: 'Non-profit giving',
  [SubTypes.Other]: 'Other',
  [SubTypes.RetirementAccounts]: 'Retirement accounts',
  [SubTypes.Roth]: 'Roth',
  [SubTypes.Savings]: 'Savings',
  [SubTypes.SepIra]: 'SEP IRA',
  [SubTypes.SimpleIRA]: 'Simple IRA',
  [SubTypes.TenantsInCommon]: 'Tenants in common',
  [SubTypes.TrustAccounts]: 'Trust accounts',
} as const satisfies Record<CompoundInvestSubTypes, string>;

const GroupSections = {
  RetirementAccounts: 'Retirement accounts',
  BrokerageAccounts: 'Brokerage accounts',
};

const SubTypeToGroupSection = {
  [SubTypes.RetirementAccounts]: GroupSections.RetirementAccounts,
  [SubTypes.ContributoryIRA]: GroupSections.RetirementAccounts,
  [SubTypes.SimpleIRA]: GroupSections.RetirementAccounts,
  [SubTypes.IRA]: GroupSections.RetirementAccounts,
  [SubTypes.Roth]: GroupSections.RetirementAccounts,
  [SubTypes.Four01k]: GroupSections.RetirementAccounts,
  [SubTypes.SepIra]: GroupSections.RetirementAccounts,
  [SubTypes.Brokerage]: GroupSections.BrokerageAccounts,
  [SubTypes.BrokerageAccounts]: GroupSections.BrokerageAccounts,
};

const SubTypeToTaxTreatment = {
  [SubTypes.Brokerage]: TaxTreatment.Taxable,
  [SubTypes.BrokerageAccounts]: TaxTreatment.Taxable,
  [SubTypes.CharitableGiving]: TaxTreatment.Retirement,
  [SubTypes.ContributoryIRA]: TaxTreatment.Taxable,
  [SubTypes.Corporation]: TaxTreatment.Taxable,
  [SubTypes.Education]: TaxTreatment.Taxable,
  [SubTypes.Four01k]: TaxTreatment.Retirement,
  [SubTypes.GeneralSavings]: TaxTreatment.Taxable,
  [SubTypes.InvestmentAccounts]: TaxTreatment.Taxable,
  [SubTypes.IRA]: TaxTreatment.Retirement,
  [SubTypes.IRAAccounts]: TaxTreatment.Retirement,
  [SubTypes.JointInvestment]: TaxTreatment.Taxable,
  [SubTypes.LimitedLiabilityCompany]: TaxTreatment.Taxable,
  [SubTypes.LivingTrust]: TaxTreatment.Taxable,
  [SubTypes.NonProfitGiving]: TaxTreatment.Retirement,
  [SubTypes.Other]: TaxTreatment.Taxable,
  [SubTypes.RetirementAccounts]: TaxTreatment.Retirement,
  [SubTypes.Roth]: TaxTreatment.Retirement,
  [SubTypes.Savings]: TaxTreatment.Taxable,
  [SubTypes.SepIra]: TaxTreatment.Retirement,
  [SubTypes.SimpleIRA]: TaxTreatment.Retirement,
  [SubTypes.TenantsInCommon]: TaxTreatment.Taxable,
  [SubTypes.TrustAccounts]: TaxTreatment.Taxable,
} as const satisfies Record<CompoundInvestSubTypes, string>;

const Constants = {
  SubTypes,
  SubTypeLabels,
  SubTypeToTaxTreatment,
  SubTypeToGroupSection,
};

function getCategoryValues(holdings: InvestmentHolding[]) {
  const holdingsByCategory = groupBy(
    holdings,
    (holding) => holding?.security?.assetCategory?.title ?? 'Others',
  );
  const categoryValues = Object.entries(holdingsByCategory).map(
    ([category, categoryHoldings]) => {
      const categoryValue = sumBy(categoryHoldings, 'institutionValue');
      const categoryReturn =
        categoryValue - sumBy(categoryHoldings, 'costBasis');
      return {
        category,
        value: categoryValue,
        totalReturn: categoryReturn,
        holdings: categoryHoldings,
      };
    },
  );

  return categoryValues;
}

const CompoundInvestUtils = {
  Constants,
  getCategoryValues,
};

export default CompoundInvestUtils;
