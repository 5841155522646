import Typography from 'components/global/Typography';
import { FlexCol, FlexRow } from 'components/global';
import { Logo } from 'components/ContentHeader/Logo';
import { useEffect } from 'react';
import { initAndTrackMobile } from 'utils/amplitude';
import { isAndroid, isIOS } from 'react-device-detect';

const MobileIllustration = () => (
  <svg
    width="201"
    height="141"
    viewBox="0 0 201 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.0603 83.835H56.5628V102.016H36.3618V110.096H84.8442V102.016H64.6432V83.835H115.146C116.752 83.8331 118.293 83.194 119.429 82.0579C120.565 80.9218 121.204 79.3814 121.206 77.7747V6.06113C121.204 4.45442 120.565 2.91404 119.429 1.77792C118.293 0.641798 116.752 0.00270289 115.146 0.000831658C115.146 0.000831658 7.66702 -0.00103957 6.0603 0.000831658C4.45358 0.00270289 1.77709 1.77792 1.77709 1.77792C0.640967 2.91404 0.00187123 4.45442 0 6.06113V77.7747C0.00187123 79.3814 0.640967 80.9218 1.77709 82.0579C2.91321 83.194 4.45358 83.8331 6.0603 83.835ZM8.0804 8.08123H113.126V75.7546H8.0804V8.08123Z"
      fill="#222222"
    />
    <rect
      x="97.9749"
      y="45.4534"
      width="83.8342"
      height="60.603"
      fill="white"
    />
    <path
      d="M177.895 109.61H100.879C98.8368 109.608 96.8791 108.796 95.4352 107.352C93.9913 105.908 93.1792 103.951 93.1772 101.909V47.9973C93.1792 45.9553 93.9913 43.9976 95.4352 42.5537C96.8791 41.1098 98.8368 40.2977 100.879 40.2957H177.895C179.937 40.2977 181.895 41.1098 183.339 42.5537C184.783 43.9976 185.595 45.9553 185.597 47.9973V101.909C185.594 103.95 184.781 105.908 183.338 107.351C181.894 108.795 179.937 109.607 177.895 109.61ZM100.879 47.9973V101.909H177.895V47.9973H100.879Z"
      fill="#222222"
    />
    <path
      d="M85.4755 117.312H193.298V125.014H85.4755V117.312Z"
      fill="#222222"
    />
  </svg>
);

function MobileMessage() {
  useEffect(() => {
    initAndTrackMobile(isAndroid ? 'Android' : isIOS ? 'iOS' : 'other');
  }, []);

  return (
    <FlexCol
      as="main"
      alignItems="center"
      bg="bg_grey"
      className="pt-md5"
      h="100vh"
    >
      <Logo fill="black" />
      <FlexCol
        bg="white"
        className="br-10  mx-auto my-md5 px-base05 pt-xl05 pb-md05"
        w="88%"
        alignItems="center"
      >
        <MobileIllustration />
        <Typography
          as="p"
          w="100%"
          fontSize="15px"
          lineHeight="21px"
          textAlign="center"
          style={{ maxWidth: 255 }}
          className="my-md5"
        >
          Compound is currently not available for mobile on your device. Please
          visit Compound on desktop.
        </Typography>
        <FlexRow bg="light_grey" h="3.25rem" w="100%" className="mt-auto br">
          <Typography
            fontSize="1rem"
            fontWeight={525}
            textAlign="center"
            className="px-base05"
          >
            app.compoundplanning.com
          </Typography>
        </FlexRow>
      </FlexCol>
    </FlexCol>
  );
}

export default MobileMessage;
