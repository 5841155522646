import { PropertyTaxScheduleType, RealEstateValuationType } from './types';
import { PrivateInvestmentProvider } from '@compoundfinance/compound-core/dist/types/equity';
import { AccountType } from 'containers/Dashboard/Accounts/types';
import { PlaidAccountProvider } from 'utils/plaid/constants';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { FundInvestmentType } from 'types/fundInvestments';
import { InvestmentValuationType } from 'utils/constants/privateInvestments';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

export const initialAssetProjections = {
  bad: null,
  neutral: null,
  positive: null,
};

const initialAssetMetadata = {
  notes: '',
  accountSnapshots: [],
  projections: {
    ...initialAssetProjections,
  },
};

const asset = {
  name: '',
  quantity: 1,
  value: null,
  ...initialAssetMetadata,
};

export const defaultOtherAsset = {
  ...asset,
  assetType: AssetTypes.Other,
};

type PlaidAccountNullBalance = Omit<PlaidAccount, 'currentBalance'> & {
  currentBalance: number | null;
};

export const defaultManualPlaidBankAccount: PlaidAccountNullBalance = {
  ownerId: '',
  ownership: { owners: null },
  subtype: '',
  officialName: '',
  currentBalance: null,
  provider: PlaidAccountProvider.SelfServe,
  type: AccountType.Depository,
  originalType: AccountType.Depository,
  mask: '',
  name: '',
  label: '',
  integrationId: '',
  institutionId: '',
  institution: {} as any,
  notes: '',
  accountSnapshots: [],
  investmentMetadatum: null,
  valuedByHoldings: false,
  assetCategory: null,
  plaidMortgage: null,
};

export const defaultManualPlaidInvestmentAccount: PlaidAccountNullBalance = {
  ...defaultManualPlaidBankAccount,
  type: AccountType.Investment,
  originalType: AccountType.Investment,
};

export const defaultManualCryptoAccount: PlaidAccountNullBalance = {
  ...defaultManualPlaidBankAccount,
  type: AccountType.Crypto,
  originalType: AccountType.Crypto,
  valuedByHoldings: true,
};

export const defaultRealEstate = {
  name: '',
  value: null,
  purchasePrice: null,
  streetAddress: null,
  zipCode: null,
  city: null,
  residenceState: null,
  ownerId: '',
  assetType: AssetTypes.RealEstate,
  valuationType: RealEstateValuationType.automatic,
  nickname: null,
  isRental: false,
  rentalIncome: null,
  purchaseDate: null,
  annualPropertyTaxRate: null,
  propertyTaxSchedule: PropertyTaxScheduleType.Annual,
  annualMaintenanceCost: null,
  ...initialAssetMetadata,
};

export const defaultLPExposure = {
  ownerId: '',
  name: '',
  fundName: '',
  firmName: '',
  type: FundInvestmentType.VentureCapital,
  assetType: AssetTypes.LP,
  currentBalance: null,
  committedCapital: null,
  legallySubscribed: true,
  events: [],
  managedByCompound: false,
  provider: null,
  ...initialAssetMetadata,
};

export const defaultGPExposure = {
  ownerId: '',
  name: '',
  fundName: '',
  firmName: '',
  type: FundInvestmentType.VentureCapital,
  assetType: AssetTypes.GP,
  currentBalance: null,
  fundLaunchYear: null,
  fundSize: null,
  managementFeeRate: null,
  estimatedCarryChargedPercent: null,
  ownershipPercent: null,
  ...initialAssetMetadata,
};

export const defaultLoan = {
  officialName: '',
  nickname: '',
  currentBalance: null,
  originalBalance: null,
  date: null,
  duration: null,
  annualInterest: null,
  type: '',
  ownerId: '',
  notes: '',
  assetType: AssetTypes.Loan,
  accountSnapshots: [],
  mortgageType: null,
  interestRateType: null,
  hasPrepaymentPenalty: null,
  monthlyPaymentAmount: null,
  projections: {
    remainingBalance: null,
    totalInterestPaid: null,
  },
};

/**
 * Mapping of AssetTypes enum values to human-friendly strings
 */
export const AssetTitles = {
  [AssetTypes.FundInvestment]: 'Fund Investments',
  [AssetTypes.CompoundFunds]: 'Compound Funds',
  [AssetTypes.LP]: 'Limited Partnerships',
  [AssetTypes.GP]: 'General Partnerships',
  [AssetTypes.Other]: 'Other Assets',
  [AssetTypes.RealEstate]: 'Real Estate',
  [AssetTypes.PrivateInvestment]: 'Private Investments',
  [AssetTypes.Loan]: 'Loans',
  [AssetTypes.Cash]: 'Cash And Equivalents',
  [AssetTypes.CreditCard]: 'Credit Cards',
  [AssetTypes.OtherLiability]: 'Other Liabilities',
  [AssetTypes.PublicInvestment]: 'Public Assets',
  [AssetTypes.CompoundInvestAccount]: 'Compound Investments',
  [AssetTypes.StartupEquity]: 'Company Equity',
  [AssetTypes.ManualBankAccount]: 'Manual Bank Account',
  [AssetTypes.ManualInvestmentAccount]: 'Manual Investment Account',
  [AssetTypes.ManualCryptoAccount]: 'Manual Crypto Account',
  [AssetTypes.Crypto]: 'Crypto',
};

export const defaultPrivateInvestment = {
  fundName: '',
  companyName: '',
  committed: null,
  shareQuantity: null,
  date: null,
  investmentStage: '',
  sharePrice: null,
  type: null,
  providerId: null,
  securityId: null,
  ownerId: '',
  ownershipPercent: null,
  provider: PrivateInvestmentProvider.Manual,
  assetType: AssetTypes.PrivateInvestment,
  changes: [],
  inactive: false,
  cancelled: false,
  estimatedValue: null,
  latestValuation: null,
  valuationType: InvestmentValuationType.EstimatedValue,
  investmentStatus: null,
  exitDate: null,
  investmentVehicle: null,
  carryPercent: null,
  platformHeld: null,
  platformName: null,
  privateInvestmentValuations: [],
  investmentEvents: [],
  isQsbsQualifiedBusiness: false,
  qsbsAssetsQualified: false,
  ...initialAssetMetadata,
};
