import { PrivateInvestment } from './equity';
import {
  PrivateEquityAccount,
  StockValuationHistory,
} from '@compoundfinance/compound-core/dist/types/equity';
import { File } from './file';
import { IntegrationStatus } from 'utils/integrations/types';
import { UserRoleType } from 'utils/constants/user';
import { IntegrationRefreshStatus } from 'utils/constants/integration';
import { ChartSettings } from 'shared/chart/types';
import { BillingType } from './billing';
import { Address } from './address';
import { ValueOf } from 'types';
import { SortMethods } from 'utils/constants/privateInvestments';
import { PartnershipManifest, PartnerTypes } from './clientPartnership';
import {
  AssetTypes,
  Owner,
} from '@compoundfinance/compound-core/dist/types/account';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { ArgyleEmployment } from 'shared/argyle/types';
import { AccountSectionSortOrder } from 'containers/Dashboard/Accounts/constants';
import { AssetFilter } from 'utils/assetFilters/types';
import { ClientAgreement } from './clientAgreement';
import { ProductVisibilities } from './productVisibilities';
import { ClientAccessSession } from './clientAccessSession';
import { TaxFilingIntro } from './taxFiling';
import { LPExposure } from './fundInvestments';
import { AdminVisibility } from 'domain/AdminVisibility';
import { ContactStatus } from 'domain/User/User';

export interface User {
  id: string;
  email: string;
  ssoId: string | null;
  ssoProvider: string | null;
  firstName: string;
  lastName: string;
  legalFirstName?: string;
  legalLastName?: string;
  reportComplete: boolean;
  onboardingComplete: boolean;
  accountAuthenticated: boolean;
  birthday: Date;
  wealthProfile: WealthProfile;
  files: File[];
  role: UserRoleType;
  isEmployee: boolean;
  phoneNumber: string | null;
  hasSpouse: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  address: Address;
  contactStatus: ContactStatus;
}

export interface TwoFactor {
  authenticated: boolean;
  method: string;
  phoneNumber?: string;
}

interface UserProfile {
  byline: string;
  contactEmails: string[];
  description: string;
  type: PartnerTypes;
  websiteLink?: string;
  preferredFirstName?: string;
  preferredLastName?: string;
}

export type PartnerUser = User & { userProfile: UserProfile };

export interface UserData {
  user: User & {
    userSetting: UserSettings;
    assetFilters: AssetFilter[];
    owners: Owner[];
  };
  financialAdvisor: {
    firstName: string;
    lastName: string;
    id: string;
    email: string;
  };
  allAdvisorTeamMembers: {
    firstName: string;
    lastName: string;
    id: string;
  }[];
  integrations: Integration[];
  clientAgreement: ClientAgreement;
  activeTaxProfile: TaxProfile;
  taxProfiles: TaxProfile[];
  plaidAccounts: PlaidAccount[];
  assets: any;
  wealthProfile: WealthProfile;
  clientPartnerships: PartnershipManifest[];
  stockValuations: { [key: string]: StockValuationHistory[] };
  hasInvestOrganizer: boolean;
  featureFlags: Record<string, boolean>;
  productVisibilities: ProductVisibilities;
  clientAccessSession: ClientAccessSession;
  taxFilingIntros: Record<string, TaxFilingIntro>;
}

export enum ExtensionStatus {
  ON = 'ON',
  OFF = 'OFF',
  FILED = 'FILED',
}

export enum OnboardingStepTypes {
  Welcome = 'welcome',
  BasicWelcomeInfo = 'basicWelcomeInfo',
  ManagedAccounts = 'managedAccounts',
  ScheduleIntroCall = 'scheduleIntroCall',
  Entities = 'entities',
  Bank = 'bank',
  Crypto = 'crypto',
  Equity = 'equity',
  Private = 'private',
  Other = 'other',
  Feedback = 'feedback',
}
export interface TaxProfile {
  id: string;
  taxableIncome: number;
  salary: number;
  bonus: number;
  irregularIncome: number;
  socialSecurityIncome: number;
  pensionIncome: number;
  interestAndDividends: number;
  qualifiedInterestAndDividends: number;
  selfEmploymentIncome: number;
  federalWithholding: number;
  stateWithholding: number;
  filingStatus: string;
  numChildDependents: number;
  numOtherDependents: number;
  year: number;
  nonEquityLtcg: number;
  nonEquityStcg: number;
  residentState: string;
  rentalIncome: number;
  four01KDeduction: number;
  mortgageInterestDeduction: number;
  propertyTaxDeduction: number;
  charitableContributionsDeduction: number;
  iraContributionsDeduction: number;
  medicalExpensesDeduction: number;
  fsaDeduction: number;
  hsaDeduction: number;
  five29Deduction: number;
  dependentCareFsaDeduction: number;
  sepIraDeduction: number;
  saltDeduction: number;
  saltNiiDeduction: number;
  medicareWages: number;
  amtFederalCredits: number;
  amtStateCredits: number;
  userId: string;
  user: User;
  extensionStatus: string;
  updatedAt?: Date;
}

export interface WealthProfile {
  id: string;
  hasTrust: boolean;
  expenses: number;
  userId: string;
  advisorDriveLink: string | null;
  updatedAt?: Date;
}

export interface Integration {
  id: string;
  publicToken: string;
  privateToken?: string;
  type: string;
  userId: string;
  argyleEmployments: ArgyleEmployment[];
  privateEquityAccounts: PrivateEquityAccount[];
  privateInvestments: PrivateInvestment[];
  lpExposures: LPExposure[];
  plaidAccounts: PlaidAccount[];
  lastSynced: Date;
  status: IntegrationStatus;
  errorStatus: IntegrationStatus;
  createdAt?: Date;
  updatedAt?: Date;
  lastSnoozed?: Date;
}

export interface PlaidIntegration extends Integration {
  refreshed?: IntegrationRefreshStatus;
}

export interface AdminUserData {
  firstName?: string;
  lastName?: string;
  taxableIncome?: number;
  email: string;
  filingStatus: string;
  companyData: PrivateEquityAccount[];
  reportComplete: boolean;
  onboardingComplete: boolean;
}

export interface Spouse {
  firstName?: string;
  lastName?: string;
  legalFirstName?: string;
  legalLastName?: string;
  birthday?: Date;
  phoneNumber?: string;
  email?: string;
}

export enum CallToAction {
  AddCompanyEquity = 'AddCompanyEquity',
  TrackRest = 'TrackRest',
  TurnOn2FA = 'TurnOn2FA',
  ShowInvestNew = 'ShowInvestNew',
  ShowPrivateMarketsPreQualified = 'ShowPrivateMarketsPreQualified',
  ShowArgyleSyncingBanner = 'ShowArgyleSyncingBanner',
  ShowArgyleSyncedBanner = 'ShowArgyleSyncedBanner',
}

export interface UserSettings {
  id?: string;
  chartSettings: ChartSettings;
  taxProfiles?: TaxProfile[];
  activeTaxProfile?: TaxProfile;
  userId?: string;
  acceptedTerms: Date | null;
  acceptedPrivacyPolicy: Date | null;
  spouse?: Spouse;
  createdAt?: Date;
  updatedAt?: Date;
  billingType?: BillingType;
  startingBalanceDate: Date;
  privateInvestmentHoldingsSort: ValueOf<typeof SortMethods>;
  bookmarkSimulations: Record<string, string | null>;
  timelineFilters: { future: any; past: any };
  clientSigned: boolean;
  activeAccountSections: Record<AssetTypes, boolean>;
  accountSectionSortOrder: AccountSectionSortOrder;
  allocationViewFilters: Record<string, any>;
  taxFormEnabledDate: string | null;
  lastOnboardingStep: OnboardingStepTypes;
  callToActionStatuses: Record<CallToAction, boolean>;
  canContactForFeedback: boolean | null;
  stripeCustomerId: string | null;
  interestedInSelfServeInvest: boolean;
  interestedInStockOptionFinancing: boolean;
  modernShareVerified?: boolean;
  modernShareCustomerId?: string;
  referralId: string;
  transactionsAdminVisibility: AdminVisibility;
}

export enum SharedUserStatus {
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
}

export interface SharedUser {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  status: SharedUserStatus;
  ssoId: string | null;
  ssoProvider: string | null;
  userId?: string;
  createdAt?: Date;
  isExpired?: boolean;
}
