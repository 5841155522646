import { useCallback } from 'react';

import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { PlaidAccountProvider } from 'utils/plaid/constants';
import { PrivateEquityAccount } from '@compoundfinance/compound-core/dist/types/equity';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import AccountPageUtils from 'containers/Dashboard/Accounts/utils';
import { AccountSectionItem } from 'types/assets/section';
import useViewAssetModal from 'containers/AssetModal/ViewAssetModal/useViewAssetModal';
import useViewCompanyEquityModal from 'containers/AssetModal/ViewCompanyEquityModal/useViewCompanyEquityModal';

function useEditAsset() {
  const { openViewAssetModal } = useViewAssetModal();
  const { openViewCompanyEquityModal } = useViewCompanyEquityModal();

  const openPlaidProviderModal = useCallback(
    (plaidAcct: PlaidAccount) => {
      openViewAssetModal((plaidAcct as CompoundAsset)?.assetType, plaidAcct);
    },
    [openViewAssetModal],
  );

  const editAsset = useCallback(
    (accountOrAsset: AccountSectionItem) => {
      const assetType = (accountOrAsset as CompoundAsset).assetType;
      const isPlaidAcct = AccountPageUtils.isPlaidAccount(accountOrAsset);
      const isPeAccount = assetType === AssetTypes.StartupEquity;
      const isManualPlaidAccount =
        isPlaidAcct &&
        (accountOrAsset as PlaidAccount).provider ===
          PlaidAccountProvider.SelfServe;
      const isPlaidProviderAcct = isPlaidAcct && !isManualPlaidAccount;

      if (isPlaidProviderAcct) {
        openPlaidProviderModal(accountOrAsset as PlaidAccount);
      } else if (isPeAccount) {
        openViewCompanyEquityModal(accountOrAsset as PrivateEquityAccount);
      } else if ('assetType' in accountOrAsset || isManualPlaidAccount) {
        openViewAssetModal(
          (accountOrAsset as CompoundAsset).assetType ||
            AssetTypes.PlaidAccount,
          accountOrAsset,
        );
      } else if (!assetType) {
        // StartupEquity has no assetType property assigned to it
        openViewAssetModal(AssetTypes.StartupEquity, accountOrAsset);
      }
    },

    [openPlaidProviderModal, openViewCompanyEquityModal, openViewAssetModal],
  );

  return editAsset;
}

export default useEditAsset;
