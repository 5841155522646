export const ASSET_CATEGORY_NAME_TO_SLUG = {
  'Public Assets': 'public-assets',
  Equities: 'equities',
  'US Equities': 'us-equities',
  'US Equities: Large Cap': 'us-equities-large-cap',
  'US Equities: Small/Mid Cap': 'us-equities-smallmid-cap',
  'US Equities: Other': 'us-equities-other',
  'International Equities': 'international-equities',
  'International Equities: Developed (ex-US)':
    'international-equities-developed-ex-us',
  'International Equities: Emerging Markets':
    'international-equities-emerging-markets',
  'International Equities: Other': 'international-equities-other',
  'Fixed Income': 'fixed-income',
  'US Fixed Income': 'us-fixed-income',
  'International Fixed Income': 'international-fixed-income',
  TIPS: 'tips',
  'Other Fixed Income': 'other-fixed-income',
  Alternatives: 'alternatives',
  'Real Estate Funds': 'real-estate-funds',
  'Other Alternatives': 'other-alternatives',
  Commodities: 'commodities',
  'Other Public Assets': 'other-public-assets',
  'Other Assets': 'other-assets',
  'Real Estate': 'real-estate',
  'Company Equity': 'company-equity',
  'Private Investments': 'private-investments',
  'Fund Investments': 'fund-investments',
  'Private Equity Fund': 'private-equity-fund',
  'Venture Capital Fund': 'venture-capital-fund',
  'Real Estate Private Fund': 'real-estate-private-fund',
  'Private Credit Fund': 'private-credit-fund',
  'Hedge Fund': 'hedge-fund',
  'Other Fund Investments': 'other-fund-investments',
  'Cash And Equivalents': 'cash-and-equivalents',
  Cash: 'cash',
  'Cash Equivalents': 'cash-equivalents',
  Cryptocurrency: 'cryptocurrency',
} as const;
export type AssetCategoryName = keyof typeof ASSET_CATEGORY_NAME_TO_SLUG;

export const ASSET_CATEGORY_SLUG_TO_NAME = {
  'public-assets': 'Public Assets',
  equities: 'Equities',
  'us-equities': 'US Equities',
  'us-equities-large-cap': 'US Equities: Large Cap',
  'us-equities-smallmid-cap': 'US Equities: Small/Mid Cap',
  'us-equities-other': 'US Equities: Other',
  'international-equities': 'International Equities',
  'international-equities-developed-ex-us':
    'International Equities: Developed (ex-US)',
  'international-equities-emerging-markets':
    'International Equities: Emerging Markets',
  'international-equities-other': 'International Equities: Other',
  'fixed-income': 'Fixed Income',
  'us-fixed-income': 'US Fixed Income',
  'international-fixed-income': 'International Fixed Income',
  tips: 'TIPS',
  'other-fixed-income': 'Other Fixed Income',
  alternatives: 'Alternatives',
  'real-estate-funds': 'Real Estate Funds',
  'other-alternatives': 'Other Alternatives',
  commodities: 'Commodities',
  'other-public-assets': 'Other Public Assets',
  'other-assets': 'Other Assets',
  'real-estate': 'Real Estate',
  'company-equity': 'Company Equity',
  'private-investments': 'Private Investments',
  'fund-investments': 'Fund Investments',
  'private-equity-fund': 'Private Equity Fund',
  'venture-capital-fund': 'Venture Capital Fund',
  'real-estate-private-fund': 'Real Estate Private Fund',
  'private-credit-fund': 'Private Credit Fund',
  'hedge-fund': 'Hedge Fund',
  'other-fund-investments': 'Other Fund Investments',
  'cash-and-equivalents': 'Cash And Equivalents',
  cash: 'Cash',
  'cash-equivalents': 'Cash Equivalents',
  cryptocurrency: 'Cryptocurrency',
} as const;
export type AssetCategorySlug = keyof typeof ASSET_CATEGORY_SLUG_TO_NAME;

export const ASSET_CATEGORY_SLUG_TO_SHORT_NAME = {
  'public-assets': 'Public Assets',
  equities: 'Equities',
  'us-equities': 'US Equities',
  'us-equities-large-cap': 'US Equities: Large Cap',
  'us-equities-smallmid-cap': 'US Equities: Small/Mid Cap',
  'us-equities-other': 'US Equities: Other',
  'international-equities': `Int'l Equities`,
  'international-equities-developed-ex-us': `Int'l Equities: Developed (ex-US)`,
  'international-equities-emerging-markets': `Int'l Equities: Emerging Markets`,
  'international-equities-other': `Int'l Equities: Other`,
  'fixed-income': 'Fixed Income',
  'us-fixed-income': 'US Fixed Income',
  'international-fixed-income': `Int'l Fixed Income`,
  tips: 'TIPS',
  'other-fixed-income': 'Other Fixed Income',
  alternatives: 'Alternatives',
  'real-estate-funds': 'Real Estate Funds',
  'other-alternatives': 'Other Alternatives',
  commodities: 'Commodities',
  'other-public-assets': 'Other Public Assets',
  'other-assets': 'Other Assets',
  'real-estate': 'Real Estate',
  'company-equity': 'Company Equity',
  'private-investments': 'Private Investments',
  'fund-investments': 'Fund Investments',
  'private-equity-fund': 'Private Equity Fund',
  'venture-capital-fund': 'Venture Capital Fund',
  'real-estate-private-fund': 'Real Estate Private Fund',
  'private-credit-fund': 'Private Credit Fund',
  'hedge-fund': 'Hedge Fund',
  'other-fund-investments': 'Other Fund Investments',
  'cash-and-equivalents': 'Cash And Equivalents',
  cash: 'Cash',
  cashEquivalents: 'Cash Equivalents',
  cryptocurrency: 'Cryptocurrency',
} as const;

export const ASSET_CATEGORY_SLUG_TO_DISPLAY_NAME = {
  'public-assets': 'Public Assets',
  equities: 'Equities',
  'us-equities': 'US Equities',
  'us-equities-large-cap': 'US Equities: Large Cap',
  'us-equities-smallmid-cap': 'US Equities: Small/Mid Cap',
  'us-equities-other': 'US Equities: Other',
  'international-equities': `Int'l Equities`,
  'international-equities-developed-ex-us': `Int'l Equities: Developed (ex-US)`,
  'international-equities-emerging-markets': `Int'l Equities: Emerging Markets`,
  'international-equities-other': `Int'l Equities: Other`,
  'fixed-income': 'Fixed Income',
  'us-fixed-income': 'US FI',
  'international-fixed-income': `Int'l FI`,
  tips: 'TIPS',
  'other-fixed-income': 'Other FI',
  alternatives: 'Alternatives',
  'real-estate-funds': 'Real Estate Funds',
  'other-alternatives': 'Other Alternatives',
  commodities: 'Commodities',
  'other-public-assets': 'Other Public Assets',
  'other-assets': 'Other Assets',
  'real-estate': 'Real Estate',
  'company-equity': 'Company Equity',
  'private-investments': 'Private Investments',
  'fund-investments': 'Fund Investments',
  'private-equity-fund': 'PF: Private Equity',
  'venture-capital-fund': 'PF: Venture Capital',
  'real-estate-private-fund': 'PF: Real Estate',
  'private-credit-fund': 'PF: Private Credit',
  'hedge-fund': 'PF: Hedge',
  'other-fund-investments': 'PF: Other',
  'cash-and-equivalents': 'Cash & Equivalents',
  cashEquivalents: 'Cash Equivalents',
  cash: 'Cash',
  cryptocurrency: 'Cryptocurrency',
} as const;

export const SUB_ASSET_CLASS_TO_ASSET_CLASS = {
  'public-assets': 'public-assets',
  equities: 'equities',
  'us-equities': 'equities',
  'us-equities-large-cap': 'equities',
  'us-equities-smallmid-cap': 'equities',
  'us-equities-other': 'equities',
  'international-equities': 'equities',
  'international-equities-developed-ex-us': 'equities',
  'international-equities-emerging-markets': 'equities',
  'international-equities-other': 'equities',
  'fixed-income': 'fixed-income',
  'us-fixed-income': 'fixed-income',
  'international-fixed-income': 'fixed-income',
  tips: 'fixed-income',
  'other-fixed-income': 'fixed-income',
  alternatives: 'other-public-assets',
  'real-estate-funds': 'other-public-assets',
  'other-alternatives': 'other-public-assets',
  commodities: 'other-public-assets',
  'other-public-assets': 'other-public-assets',
  'real-estate': 'real-estate',
  'company-equity': 'company-equity',
  'private-investments': 'private-investments',
  'fund-investments': 'fund-investments',
  'private-equity-fund': 'fund-investments',
  'venture-capital-fund': 'fund-investments',
  'real-estate-private-fund': 'fund-investments',
  'private-credit-fund': 'fund-investments',
  'hedge-fund': 'fund-investments',
  'other-fund-investments': 'fund-investments',
  'cash-and-equivalents': 'cash-and-equivalents',
  cashEquivalents: 'cash-and-equivalents',
  cash: 'cash-and-equivalents',
  cryptocurrency: 'cryptocurrency',
  'other-assets': 'other-assets',
} as const;
