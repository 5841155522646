import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { LegalPerson } from 'containers/Dashboard/PartnerPortal/Content/Intake/Estate/types';

export enum CashflowProfileUpdateType {
  FilingStatus = 'filingStatus',
  ResidentState = 'residentState',
  NumDependents = 'numChildDependents',
  AnnualIncome = 'salary',
  MonthlyExpenses = 'expenses',
}

export enum CashflowActivityType {
  ProfileUpdates = 'profileUpdates',
  Transfers = 'transfers',
  OtherTransactions = 'otherTransactions',
  GrowthRates = 'growthRates',
  PaydownRates = 'paydownRates',
  FutureAccounts = 'futureAccounts',
  Homes = 'homes',
}

export enum CashflowTransactionType {
  HomeAnnualMaintenance = 'homeAnnualMaintenance',
  HomeClosingCost = 'homeClosingCost',
  HomeRentalIncome = 'homeRentalIncome',
  PrivateMortgageInsurance = 'privateMortgageInsurance',
  AdditionalEmployerRetirementContribution = 'additionalEmployerRetirementContribution',
  SelfEmploymentIncome = 'selfEmploymentIncome',
  SocialSecurity = 'socialSecurity',
  Pension = 'pension',
  Bonus = 'bonus',
  OtherIncome = 'otherIncome',
}

export enum CashflowFrequencyType {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly',
}

export enum DateTriggerType {
  RetirementDate = 'retirementDate',
  Age = 'age',
}

export type DateTriggerRetirement = {
  type: DateTriggerType.RetirementDate;
  legalPersonId: LegalPerson['id'];
};
export type DateTriggerAge = {
  type: DateTriggerType.Age;
  legalPersonId?: LegalPerson['id'];
  age?: number;
};
export type DateTrigger = DateTriggerRetirement | DateTriggerAge;

interface CashflowActivityCore {
  id?: string;
  activityType?: CashflowActivityType;
  date?: Date | string | null;
  startTrigger?: DateTrigger | null;
  notes?: string;
  unknownDate?: boolean;
  // if true, overrides activity date to always be current day
  // (when cashflow is calculated and presented to end users)
  alwaysToday?: boolean | null;
}

export interface CashflowProfileUpdate
  extends Omit<CashflowActivityCore, 'date'> {
  // "filingStatus", "residentState", "monthlyExpense"
  type: CashflowProfileUpdateType;
  // "from" value which this update transitions from.
  // fromValue, prevDate, and prevSalary are optional because we do not store it in DB.
  fromValue?: string | number;
  prevDate?: string;
  prevSalary?: number;
  toValue: string | number | null;
  date: Date | string | null;
  name?: string | null;
}

export enum CashflowTransferType {
  Default = 'default',
  LiabilityPayoff = 'liabilityPayoff',
  MortgageDownPayment = 'mortgageDownpayment',
  FutureAccount = 'futureAccount',
  AssetSale = 'assetSale',
  RetirementContribution = 'retirementContribution',
}

// Fields used to track capital gains associated with a cashflow activity
export interface CashflowCGFields {
  id?: string;
  date?: Date | string | null;
  endDate?: Date | string | null;
  endTrigger?: DateTrigger | null;
  // if true, defaults to recurring monthly unless frequency is specified
  recurring?: boolean;
  // how frequent does the activity reoccur (unit is defaulted to month)
  recurringFrequency?: number | null;
  // the unit at which the frequency value is given
  recurringFrequencyType?: CashflowFrequencyType | null;
  isPublicInvestmentSale: boolean | null;
  isPrivateInvestmentSale: boolean | null;
  ltcg: number | null;
  stcg: number | null;
  costBasis: number | null;
  type?: CashflowTransferType;
  // Does this transfer represent a sale
  qualifyingDisposition: boolean | null;
}

export type RetirementContributionType =
  | 'trad401k'
  | 'roth401k'
  | 'tradIra'
  | 'rothIra';

export interface CashflowTransfer
  extends CashflowActivityCore,
    CashflowCGFields {
  // E.g. "Manual rebalance"
  name?: string;
  // ID of the account which transfer originates from
  senderId: 'salary' | string | null;
  // ID of the account which receives transfer
  recipientId: 'newAccount' | string | null;

  amount: number | null;

  // If specified, overrides the amount value, with percentage of sender's total balance at the time of transfer
  percentageAmount?: number | null;

  heldForYear: boolean | null;

  type: CashflowTransferType;

  // If the transfer is a retirement contribution
  contributionPercentage?: number | null;
  retirementContributionType?: RetirementContributionType | null;
  catchupContributionPercentage?: number | null;
  employerMatchPercentage?: number | null;
  // Account ID — Future account associated with retirement contribution
  futureAccountId?: string | null;
  householdMemberId?: string | null;
}

export interface CashflowFutureAccount extends CashflowActivityCore {
  name?: string;
  assetType: AssetTypes | null;
  amount: number | null;
  currentBalance: number;
  // Loan specific fields
  annualInterest: number | null;
  maturity: number | null;
  // ID of the account which funds the
  fundingAccountId: string | null;
  // ID of the CashflowTransfer that ties to this future account
  transferId: string | null;

  ltcg: number | null;
  stcg: number | null;

  institution: string | null;

  subtype?: RetirementContributionType | null;
}

export enum CashflowHomePurchaseType {
  Cash = 'cash',
  MortgageDownPayment = 'mortgageDownPayment',
  MortgageAll = 'mortgageAll',
}

export enum CashflowHomePaydownMethod {
  PrincipleAndInterest = 'principleAndInterest',
  InterestOnly = 'interestOnly',
}

export enum CashflowHomePropertyTaxScheduleType {
  Annual = 'annual',
  Monthly = 'monthly',
}

export interface CashflowHome extends CashflowActivityCore {
  name?: string;
  amount: number | null;
  // Home ID — Future account associated with property
  futureHomeId: string | null;
  // Mortgage ID — Future account associated with mortgage
  futureMortgageId: string | null;
  // ID of the mortgage paydown
  paydownId: string | null;
  // Method of paydown
  paydownMethod: CashflowHomePaydownMethod | null;
  // Maturity associated with paydown
  paydownMaturity: number | null;
  // Tax collected based on the value of a property
  propertyTax: number | null;
  // The frequency at which the property tax is paid
  propertyTaxSchedule: CashflowHomePropertyTaxScheduleType | null;
  // Residence status (residence or rental)
  isRental: boolean | null;
  // Rental income
  rentalIncome: number | null;
  // ID of the account which funds the down payment or funds the real estate purchase
  fundingAccountId: string | null;
  // ID of the account which funds the down payment
  downPaymentAmount: number | null;
  // In the case where the user performs an all cash real estate purchase, make sure this corresponds to an existing transfer
  allCashTransferId: string | null;
  // Is the user paying for the home in Cash, a full mortgage, or mortgage with a downpayment
  purchaseType: CashflowHomePurchaseType | null;
  // Estimated annual maintenance cost
  annualMaintenance: number | null;
  // Estimated closing cost
  closingCost: number | null;

  // Interest rate of mortgage
  mortgageInterest: number | null;

  // Indicates whether private mortgage insurance is required for the loan
  hasPrivateMortgageInsurance: boolean | null;
  // The PMI rate charged over the loan amount annually until loan loan balance fall below a certain threshold
  privateMortgageInsuranceRate: number | null;

  ltcg: number | null;
  stcg: number | null;

  streetAddress: string | null;
  city: string | null;
  residenceState: string | null;
  zipCode: string | null;

  // Tracks the last time Zillow data is used to populate pricing data
  lastSyncedFromZillow: Date | null;
}

type CashflowRateCore = Omit<CashflowActivityCore, 'filteredIn'> & {
  accountId: string | null;
};

export interface CashflowGrowthRate extends CashflowRateCore {
  // Rate of growth
  rate: number | null;
}

export interface CashflowPaydownRate extends CashflowRateCore {
  amount: number;
  // Are payments being made on an annual basis. If this is false
  // we assume they are being made on a monthly basis
  annual: boolean | null;
}

interface CashflowBalanceUpdate extends CashflowActivityCore {
  accountId: string;
  // AssetType
  accountType: string;
  // Updated balance
  balance: number;
}

export interface CashflowOtherTransaction
  extends CashflowActivityCore,
    CashflowCGFields {
  name?: string;
  accountId: string | null;
  amount: number | null;
  cashflowIn: boolean | null;
  transactionType?: CashflowTransactionType;
  isTaxable: boolean | null;
  startTrigger: DateTrigger | null;
  endTrigger: DateTrigger | null;
  rate?: number | null;
}

type CashflowAdvisor = {
  firstName: string;
  lastName: string;
} | null;

export interface Cashflow {
  id?: string;
  name: string;
  profileUpdates: CashflowProfileUpdate[];
  transfers: CashflowTransfer[];
  growthRates: CashflowGrowthRate[];
  groupGrowthRates: Partial<Record<AssetTypes, number | null>>;
  paydownRates: CashflowPaydownRate[];
  balanceUpdates: CashflowBalanceUpdate[];
  otherTransactions: CashflowOtherTransaction[];
  futureAccounts: CashflowFutureAccount[];
  homes: CashflowHome[];
  accountFilters: Record<string, boolean>;
  simulationFilters: Record<string, string | null>;
  inflowAccountId: string;
  outflowAccountId: string;
  notes: string;
  advisorId?: string | null;
  advisor: CashflowAdvisor;
  fetched?: boolean;
  updatedAt?: Date;
  rehydrated?: boolean;
  bookmarked?: boolean;
  userId: string;
}

// Generic type for proving valid typing for arbitrary assets
export type CashflowFormValues<I> = I & {
  eventName?: string;
  activityType?: CashflowActivityType;
};

export type CashflowActivity = CashflowFormValues<
  | CashflowBalanceUpdate
  | CashflowPaydownRate
  | CashflowGrowthRate
  | CashflowProfileUpdate
  | CashflowTransfer
  | CashflowOtherTransaction
  | CashflowFutureAccount
  | CashflowHome
>;

export type CashflowHoverType =
  | CashflowActivityType
  | CashflowProfileUpdateType
  | CashflowTransferType
  | AssetTypes
  | null;

export interface CashflowSettings {
  id?: string;
  bookmarkCashflowId: string | null;
  userCashflowOrder: string[];
  advisorCashflowOrder: string[];
  userId?: string;
}
