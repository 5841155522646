import * as GoogleUtils from './google';
import * as AppleUtils from './apple';
import * as Auth0Utils from './auth0';
import { pickBy } from 'lodash';
import { AuthMethods, RedirectState } from './types';
import { v4 as uuid } from 'uuid';

export const SESSION_STORAGE_REDIRECT_URL_KEY = 'redirectUrl';
export const AUTHENTICATION_TOKEN_KEY = 'compound_auth';
export const OAUTH_STATE_TOKEN_KEY = 'oauth-state-token';
export const REGISTRATION_QUERY_PARAMETER_NAMES = [
  'invitation_code',
  'request_access_id',
  'referral_code',
];

function getStateFromQueryParameters() {
  const state = pickBy(
    Object.fromEntries(new URLSearchParams(window.location.search)),
    (value, key) => {
      return value && REGISTRATION_QUERY_PARAMETER_NAMES.includes(key);
    },
  );
  return state as RedirectState;
}

function getCSRFTokenKey(ssoProvider: AuthMethods) {
  return `${OAUTH_STATE_TOKEN_KEY}-${ssoProvider}`;
}

export function getLoginUrl(ssoProvider: AuthMethods) {
  const csrfToken = uuid();
  window.localStorage.setItem(getCSRFTokenKey(ssoProvider), csrfToken);
  const state = {
    ...getStateFromQueryParameters(),
    csrf_token: csrfToken,
  };
  if (ssoProvider === AuthMethods.Google) {
    return GoogleUtils.getLoginUrl(state);
  } else if (ssoProvider === AuthMethods.Apple) {
    return AppleUtils.getLoginUrl(state);
  } else if (ssoProvider === AuthMethods.Auth0) {
    return Auth0Utils.getLoginUrl(state);
  } else if (ssoProvider === AuthMethods.AdvisorRedirect) {
    return `/api/auth/login?returnTo=${window.location.pathname}`;
  }

  return '/';
}

export { AuthMethods };
