import { ChartData } from 'containers/Dashboard/Accounts/NetWorthChart/LineChart';
import { useCompoundFetchImmutable } from 'hooks/useCompoundFetch';
import moment from 'moment';
import { useMemo } from 'react';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

const SYMBOL_TO_COLOR = {
  '^RUA': 'var(--colors-purple9)',
  '^ACWI': 'var(--colors-yellow9)',
  '^ACWIEXUS': 'var(--colors-orange9)',
  '^LBUSTRUU': 'var(--colors-blue9)',
};

export const SYMBOL_TO_NAME = {
  '^RUA': 'Russell 3000',
  '^ACWI': 'MSCI ACWI',
  '^ACWIEXUS': 'MSCI ACWI ex US',
  '^LBUSTRUU': 'US Aggregate Bond',
};

async function getStockPrices(
  _,
  symbol: string,
  dates: DateString[],
  startDate: string | Date,
  endDate: string | Date,
) {
  try {
    const { data } = await buildRequest(
      '/api/stockTickerPrice/prices',
      true,
      {},
      REQUEST_TYPES.GET,
      {
        symbol,
        beginDateFilter: startDate,
        endDateFilter: endDate,
      },
    );
    if (data && data.length > 0) {
      const sortedData = data.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
      );
      const firstPrice = sortedData[0].price;
      const processedData: ChartData[] = sortedData.map(({ date, price }) => ({
        date: new Date(date),
        net: Math.round(((price - firstPrice) / firstPrice) * 10_000) / 100,
      }));
      // stock data is missing bank holidays, so we need to fill in the gaps
      const fullData: ChartData[] = [];
      let tickerPointer = 0;
      for (let i = 0; i < dates.length; i++) {
        while (
          processedData.length > tickerPointer &&
          moment(dates[i]).isAfter(processedData[tickerPointer].date, 'day')
        ) {
          tickerPointer++;
        }
        if (
          processedData.length > tickerPointer &&
          moment(dates[i]).isSame(processedData[tickerPointer].date, 'day')
        ) {
          fullData.push(processedData[tickerPointer]);
          tickerPointer++;
        } else {
          fullData.push({
            date: dates[i],
            net: fullData[i - 1]?.net ?? 0,
          });
        }
      }
      return {
        data: fullData,
        name: SYMBOL_TO_NAME[symbol],
        color: SYMBOL_TO_COLOR[symbol] ?? 'blue',
      };
    }
    return null;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

function useGetBenchmarks(dates: DateString[]) {
  const startDate = useMemo(
    () => DateUtils.startOfDayNative(dates[0]).toISOString(),
    [dates],
  );
  const endDate = useMemo(
    () =>
      DateUtils.startOfDayNative(
        dates.length > 0 ? dates[dates.length - 1] : new Date(),
      ).toISOString(),
    [dates],
  );
  const { data: RUAData } = useCompoundFetchImmutable<{
    data: ChartData[];
    name: string;
    color: string;
  } | null>(
    ['/api/stockTickerPrice/prices', '^RUA', dates, startDate, endDate],
    {
      fetcher: getStockPrices,
    },
  );
  const { data: ACWIEXUSData } = useCompoundFetchImmutable<{
    data: ChartData[];
    name: string;
    color: string;
  } | null>(
    ['/api/stockTickerPrice/prices', '^ACWIEXUS', dates, startDate, endDate],
    {
      fetcher: getStockPrices,
    },
  );
  const { data: LBUSTRUUData } = useCompoundFetchImmutable<{
    data: ChartData[];
    name: string;
    color: string;
  } | null>(
    ['/api/stockTickerPrice/prices', '^LBUSTRUU', dates, startDate, endDate],
    {
      fetcher: getStockPrices,
    },
  );
  const { data: ACWIData } = useCompoundFetchImmutable<{
    data: ChartData[];
    name: string;
    color: string;
  } | null>(
    ['/api/stockTickerPrice/prices', '^ACWI', dates, startDate, endDate],
    {
      fetcher: getStockPrices,
    },
  );

  return { RUAData, ACWIData, ACWIEXUSData, LBUSTRUUData };
}

export default useGetBenchmarks;
