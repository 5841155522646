export const beautifyDomain = (domain: [number, number]) => {
  const [minimum, maximum] = domain;
  return [Math.floor(minimum), Math.ceil(maximum)] as const;
};

export const extendDomainPercentually = (
  domain: [number, number],
  percentage: number,
) => {
  const [minimum, maximum] = domain;
  const range = maximum - minimum;
  const extension = range * percentage;
  return [minimum - extension, maximum + extension] as const;
};

// Scales min and max value for chart to be displayed nicely
export const extendDomainNicely = (domain: [number, number]) => {
  const [minimum, maximum] = domain;
  const minMagnitude = Math.pow(
    10,
    Math.floor(Math.log10(Math.abs(minimum))) - 1,
  );
  const maxMagnitude = Math.pow(
    10,
    Math.floor(Math.log10(Math.abs(maximum))) - 1,
  );

  return [
    minimum === 0 ? -1 : Math.floor(minimum - minMagnitude),
    maximum === 0 ? 1 : Math.ceil(maximum + maxMagnitude),
  ] as const;
};
