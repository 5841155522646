const BaseRoutes = {
  Home: '/',

  Access: '/access',

  Settings: '/settings',
  SettingsPersonalTab: '/settings/personal',
  SettingsTax: '/settings/tax',
  SettingsTab: '/settings/:tab',
  Vault: '/vault',
  Taxes: '/taxes',
  Onboard: '/onboard',
  Auth: '/auth',
  Mobile: '/mobile',
  MobileDocuments: '/mobile/documents',
  MobileDocumentsTab: '/mobile/documents/:documentId',
  DocumentsMagicLink: '/reports-private',
  DocumentsMagicLinkTab: '/reports-private/:sig',
  Processing: '/processing',
  Admin: '/admin',

  EquityV1: '/old-equity',
  EquityV1Tab: '/old-equity/:tab',
  OldEquityV2: '/equity-v2',
  OldEquityV2Tab: '/equity-v2/:tab',

  EquityModeling: '/equity-modeling',
  EquityModelingTab: '/equity-modeling/:scenarioId',
  EquityModelingStrategyTab: '/equity-modeling/:scenarioId/s/:strategyId',

  AdvisorTask: '/advisor-task',
  AdvisorTaskTab: '/advisor-task/:tab',

  Task: '/task',
  TaskTab: '/task/:tab',

  Membership: '/membership',
  TaxFiling: '/tax-filing',
  TaxFilingApril: '/tax-filing/april/:taxYear/:taxFilingId',

  PartnerSearch: '/partner-search',
  StripeDashboard: '/stripe',
  ReadmeManual: '/manual',
  IPOPriceUpdate: '/ipo-price-update',
  Timeline: '/timeline',

  CoinbaseOAuth: '/coinbase/callback',
  GeminiOAuth: '/gemini/callback',
  CartaOAuth: '/carta/callback',

  Maintenance: '/maintenance',
  Register: '/register',
  InvestOnboarding: '/invest-onboard',
  Borrow: '/borrow',
  GetStarted: '/get-started',
  AccountNotAvailable: '/account-not-available',

  SellingCompanyEquity: '/selling-company-equity',
  Secondary: '/services/secondary-market/secondary-research-app',

  // This is a temporary route for marketing BCRED (Private Markets opportunity)
  BCRED: '/bcred',
  // This is a temporary route for marketing BREIT(Private Markets opportunity)
  BREIT: '/breit',
} as const;

export const AdvisorRoutes = {
  AdvisorSearch: '/advisor-search',
  ClientSettings: '/client-settings',
  AdvisorAdminSettings: '/advisor-admin-settings',
  AdvisorAdminSettingsTab: '/advisor-admin-settings/:tab',
  DocumentsAdmin: '/documents-admin',
  DocumentsAdminViewer: '/documents-admin/:userId/:documentId',
  EquityAdmin: '/equity-admin',
} as const;

export const TeamRoutes = {
  Team: '/team',
  PrivateWealth: '/team/private-wealth',
  Advisors: '/team/advisor',
  AdvisorsReport: '/team/advisor/:report',
  PartnerPortal: '/team/partner-portal',
  PartnerPortalIntake: '/team/partner-portal/estate/intake',
  PartnerPortalDeep: '/team/partner-portal/:portalType/:tab?',
  Reports: '/team/reports',
  Documents: '/team/documents',
  DocumentsTab: '/team/documents/:documentId',
} as const;

export const LegacyTeamRoutes = {
  LegacyPrivateWealth: '/private-wealth',
  LegacyAdvisors: '/advisor',
  LegacyAdvisorsReport: '/advisor/:report',
} as const;

const ProductRoutes = {
  Services: '/services',
  ServicesOverview: '/services/overview',
  StartupLoan: '/services/startup-backed-loan',
  SecondaryMarket: '/services/secondary-market',
  Lending: '/services/lending',
  Protection: '/services/protection',
  Philanthropy: '/services/philanthropy',
  StartupEquityLiquidity: '/services/startup-equity-liquidity',
  Lifestyle: '/services/lifestyle',
  PrivateMarkets: '/services/private-markets',
  PrivateMarketsTab: '/services/private-markets/:tab',
  PublicInvesting: '/services/public-investing',
  Tax: '/tax',
  TaxYear: '/tax/:year',
  TaxOnboard: '/services/tax-onboard',
} as const;

export const LegacyProductRoutes = {
  LegacyStockOptionFinancing: '/stock-option-financing',
  LegacyStartupLoan: '/startup-backed-loan',
  LegacyPrivateMarkets: '/private-markets',
  LegacyPrivateMarketsTab: '/private-markets/:tab',
  LegacyTax: '/tax',
  LegacyTaxYear: '/tax/:year',
  LegacyTaxOnboard: '/tax-onboard',
  LegacyPartnerPortal: '/partner-portal',
  LegacyPartnerPortalIntake: '/partner-portal/estate/intake',
  LegacyPartnerPortalDeep: '/partner-portal/:portalType/:tab?',
} as const;

const WealthAccountRoutes = {
  Homepage: '/wealth/homepage',
  Accounts: '/wealth/accounts',
  EquityV2: '/wealth/accounts/equity',
  EquityV2Tab: '/wealth/accounts/equity/:tab',
  CryptoInvestments: '/wealth/accounts/crypto',
  CryptoInvestmentsTab: '/wealth/accounts/crypto/:tab',
  FundInvestments: '/wealth/accounts/fund-investments',
  FundInvestmentsTab: '/wealth/accounts/fund-investments/:tab',
  CompoundFunds: '/wealth/accounts/compound-funds',
  CompoundFundsTab: '/wealth/accounts/compound-funds/:tab',
  PublicInvestments: '/wealth/accounts/public-investments',
  PublicInvestmentsTab: '/wealth/accounts/public-investments/:tab',
  Cash: '/wealth/accounts/cash',
  CashTab: '/wealth/accounts/cash/:tab',
  CompoundInvest: '/wealth/accounts/compound-invest',
  CompoundInvestTab: '/wealth/accounts/compound-invest/:tab',
  PrivateInvestments: '/wealth/accounts/private-investments',
} as const;

const WealthRoutes = {
  Wealth: '/wealth',
  Transactions: '/wealth/transactions',
  CompoundFlow: '/wealth/compound-flow',
  AccountOrganizer: '/wealth/account-organizer',
  PlanningHome: '/wealth/planning',
  Planning: '/wealth/planning/cashflow',
  PlanningItem: '/wealth/planning/cashflow/:plan',
  ...WealthAccountRoutes,
} as const;

export const LegacyWealthAccountRoutes = {
  LegacyAccounts: '/accounts',
  LegacyAssetAllocation: '/asset-allocation',
  LegacyEquityV2: '/equity',
  LegacyEquityV2Tab: '/equity/:tab',
  LegacyCryptoInvestments: '/crypto',
  LegacyCryptoInvestmentsTab: '/crypto/:tab',
  LegacyFundInvestments: '/fund-investments',
  LegacyFundInvestmentsTab: '/fund-investments/:tab',
  LegacyPublicInvestments: '/public-investments',
  LegacyPublicInvestmentsTab: '/public-investments/:tab',
  LegacyCompoundInvest: '/compound-invest',
  LegacyCompoundInvestTab: '/compound-invest/:tab',
  LegacyPrivateInvestments: '/private-investments',
} as const;

export const LegacyWealthRoutes = {
  LegacyCompoundFlow: '/compound-flow',
  LegacyPlanningHome: '/planning',
  LegacyPlanning: '/planning/cashflow',
  LegacyPlanningItem: '/planning/cashflow/:plan',
} as const;

export const CompoundRoutes = {
  ...BaseRoutes,
  ...AdvisorRoutes,
  ...WealthRoutes,
  ...LegacyWealthRoutes,
  ...LegacyWealthAccountRoutes,
  ...ProductRoutes,
  ...LegacyProductRoutes,
  ...TeamRoutes,
  ...LegacyTeamRoutes,
} as const;

export const pathToRouteName = new Map<string, string>();
Object.entries(CompoundRoutes).forEach(([route, value]) => {
  pathToRouteName.set(value, route);
});

export enum PortalSlugs {
  Estate = 'estate',
  Tax = 'tax',
  StockOption = 'stock-option-financing',
}

export const EMAIL_ADDRESS_CONCIERGE = 'concierge@compoundplanning.com';
export const COMPOUND_LEGAL_URL = 'https://legal.compoundplanning.com';
export const COMPOUND_MANUAL_URL = 'https://manual.compoundplanning.com';
const ENABLE_DEMO_EDIT_MODE_KEY = 'enable-demo-edit-mode';

const isDemo = () => {
  return (
    window.location.hostname.startsWith('demo') &&
    !localStorage.getItem(ENABLE_DEMO_EDIT_MODE_KEY)
  );
};

/**
 * This util denotes whether we are accessing the production URL.
 * This can be spoofed by setting the query param 'use_prod_url'=true.
 */
const isProduction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const forceProdURL = urlParams.get('use_prod_url');
  return (
    window.location.hostname === 'app.withcompound.com' ||
    window.location.hostname === 'app.compoundplanning.com' ||
    window.location.hostname === 'advisor.compoundplanning.com' ||
    forceProdURL ||
    isDemo()
  );
};

const isStaging = () => {
  return window.location.hostname.includes('staging');
};

const getClientUrl = () => {
  if (isProduction()) {
    return 'https://app.compoundplanning.com';
  } else if (isStaging()) {
    return 'https://staging.compoundplanning.com';
  }
  return 'http://localhost:3000';
};

const URLUtils = { isDemo, isProduction, isStaging, getClientUrl };

export default URLUtils;
