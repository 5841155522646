/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */
import { Switch, Route, Redirect } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css';
import { isMobileOnly } from 'react-device-detect';
import Authorize from 'containers/Authorize/Loadable';
import ScrollToTop from 'components/global/scroll';
import useTypedSelector from 'hooks/typedSelector';
import Loader, { LoaderWrap } from 'components/Loader';
import { CompoundRoutes } from 'utils/constants/url';
import MobileMessage from 'components/MobileMessage';
import { isEqual } from 'lodash';
import loadable from 'utils/loadable';
import Maintenance from 'containers/Maintenance';
import MobileRegister from 'containers/Register/mobile';
import DocumentMagicLinkViewer from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentMagicLinkViewer';
import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryFallback } from 'components/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';

const LoadableVerifyEmail = loadable(
  () => import('containers/Authorize/verify-email'),
  {
    fallback: LoaderWrap,
  },
);
const LoadableLogin = loadable(() => import('containers/Login'), {
  fallback: LoaderWrap,
});
const LoadableRegister = loadable(() => import('containers/Register'), {
  fallback: LoaderWrap,
});
const LoadableDashboard = loadable(() => import('containers/Dashboard'), {
  fallback: LoaderWrap,
});
const LoadableMobileMessage = loadable(
  () => import('components/MobileMessage'),
  {
    fallback: Loader,
    delay: 300,
  },
);
const LoadableProcessing = loadable(() => import('components/ProcessingPage'), {
  fallback: Loader,
});
const LoadableAccountNotAvailable = loadable(
  () => import('containers/AccountNotAvailable'),
  {
    fallback: Loader,
  },
);
const LoadableStripeDashboard = loadable(
  () => import('containers/StripeDashboard'),
  {
    fallback: Loader,
  },
);
const LoadableManual = loadable(() => import('containers/Manual'), {
  fallback: Loader,
});
const LoadableCoinbaseOAuth = loadable(
  () => import('components/CoinbaseOAuth'),
  {
    fallback: <LoaderWrap />,
  },
);

const LoadableCartaOAuth = loadable(() => import('components/CartaOAuth'), {
  fallback: <LoaderWrap />,
});

function App() {
  const { loggedIn, loaded, userId, userName, adminName, adminUser } =
    useTypedSelector(
      (state) => ({
        loggedIn: state.global.loggedIn,
        loaded: state.global.loaded,
        userId: state.global.user.id,
        userName: `${state.global.user.firstName} ${state.global.user.lastName}`,
        adminName: `${state.admin.adminUser.firstName} ${state.admin.adminUser.lastName}`,
        adminUser: state.admin.adminUser,
      }),
      // wrap `isEqual` to get the type inference correct for `useTypedSelector`
      (a, b) => isEqual(a, b),
    );

  if (loaded) {
    // When the app first loads, `user` is set to the admin user then changes to be the client they are viewing.
    // We will default to the `adminUser` so that this value doesn't switch
    datadogRum.setUser({
      id: adminUser.id !== '' ? adminUser.id : userId,
      name: adminUser.id !== '' ? adminName : userName,
      clientId: adminUser.id === '' ? userId : undefined,
      clientName: adminUser.id === '' ? userName : undefined,
    });
  }

  const isLoggedInOrLoading = loggedIn || !loaded;
  const HomeComponent = isLoggedInOrLoading ? LoadableDashboard : LoadableLogin;

  if (isMobileOnly) {
    if (
      window.location.pathname === CompoundRoutes.Access ||
      window.location.pathname === CompoundRoutes.Register
    ) {
      return <MobileRegister />;
    }
    if (
      window.location.pathname.startsWith(CompoundRoutes.DocumentsMagicLink)
    ) {
      return (
        <Route
          path={CompoundRoutes.DocumentsMagicLinkTab}
          component={DocumentMagicLinkViewer}
        />
      );
    }
    return <MobileMessage />;
  }

  const enableMaintenance = !!window.localStorage.getItem(
    'enable-compound-maintenance',
  );

  return (
    <ScrollToTop>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        <Switch>
          <Route
            exact
            path={CompoundRoutes.Maintenance}
            component={Maintenance}
          />
          {enableMaintenance && (
            <Redirect from="*" to={CompoundRoutes.Maintenance} />
          )}
          <Route exact path={CompoundRoutes.Auth} component={Authorize} />
          <Route
            exact
            path={CompoundRoutes.Mobile}
            component={LoadableMobileMessage}
          />
          <Route
            exact
            path={CompoundRoutes.Processing}
            component={LoadableProcessing}
          />
          <Route
            exact
            path={CompoundRoutes.AccountNotAvailable}
            component={LoadableAccountNotAvailable}
          />
          <Route
            exact
            path={CompoundRoutes.StripeDashboard}
            component={
              isLoggedInOrLoading && false
                ? LoadableStripeDashboard
                : LoadableLogin
            }
          />
          <Route
            exact
            path={CompoundRoutes.ReadmeManual}
            component={isLoggedInOrLoading ? LoadableManual : LoadableLogin}
          />
          <Route
            exact
            path={CompoundRoutes.CoinbaseOAuth}
            component={LoadableCoinbaseOAuth}
          />
          <Route
            exact
            path={CompoundRoutes.CartaOAuth}
            component={LoadableCartaOAuth}
          />
          <Route
            path={CompoundRoutes.DocumentsMagicLinkTab}
            component={DocumentMagicLinkViewer}
          />
          <Route
            path={[CompoundRoutes.Access, CompoundRoutes.Register]}
            component={LoadableRegister}
          />
          <Route path={'/verify-email'} component={LoadableVerifyEmail} />
          <Route path={CompoundRoutes.Home} component={HomeComponent} />
          <Redirect from="*" to="" />
        </Switch>
      </ErrorBoundary>
    </ScrollToTop>
  );
}

export default App;
