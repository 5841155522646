import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Makes using CRUD actions for search params easier.
 *
 * const { pushSearchParams, replaceSearchParams, searchParams } = useSearchParams();
 * pushSearchParams((previous) => ({ ...previous, filter: 3 }));
 * replaceSearchParams((previous) => ({ ...previous, filter: 3 }));
 *
 * @returns
 */
const useSearchParams = () => {
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = Object.fromEntries(
    new URLSearchParams(search).entries(),
  );

  const setSearchParams = useCallback(
    (method) => (setter) =>
      history[method]({
        search: new URLSearchParams(setter(searchParams)).toString(),
      }),
    [history, searchParams],
  );

  const pushSearchParams = useMemo(
    () => setSearchParams('push'),
    [setSearchParams],
  );

  const replaceSearchParams = useMemo(
    () => setSearchParams('replace'),
    [setSearchParams],
  );

  return {
    searchParams,
    pushSearchParams,
    replaceSearchParams,
  };
};

export default useSearchParams;
