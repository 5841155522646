export const VIEWPORT_PADDING = 32;

export const SHOW_CONTEXT_EVENT = 'showContext';
export const HIDE_CONTEXT_EVENT = 'hideContext';
export const ADD_TOAST_EVENT = 'addToast';
export const CLEAR_TOAST_EVENT = 'clearToast';
export const CLEAR_LATEST_TOAST = 'clearLatestToast';
export const CLEAR_ALL_TOASTS_EVENTS = 'clearAllToasts';
export const CLEAR_GROUP_TOASTS_EVENTS = 'clearGroupToasts';
export const ADD_NEW_BANK_ACCOUNT_TOAST_EVENT = 'addNewBankAccountToast';

// Default lifetime of a toasts (in ms)
export const DEFAULT_TOAST_LIFETIME = 4_000;

export const TOAST_WIDTH = 356;

export enum ToastType {
  Normal,
  Info,
  Action,
  Success,
  Error,
  Warning,
  Promise,
}
